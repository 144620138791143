import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './CwsCommand.css';
import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';

const CwsCommand = ({ name, parameters, selectedObjectName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const { language } = useLanguage();
    const text = texts[language].commands.find(cmd => cmd.name === name);
    const command_in = texts[language].command_in;
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 300);
    };

    const commandText = `${selectedObjectName}.${text.name}(${parameters.map(param =>
        typeof param === 'string' ? `"${param}"` : param
    ).join(", ")})`;

    return (
        <div className="Cws-command-con" onClick={handleToggle}>
            <div className="Cws-command-header">
                {text.name === 'clones.append(A)' || text.name === 'stop()' ? (
                    // Render only the name when text.name is 'clones.append' or 'stop'
                    <>
                        <span className="Cws-command-name" style={{ marginLeft: '10px' }}>{text.name}</span>
                        <div className="Cws-command-tooltip">{text.description}</div>
                    </>
                ) : (
                    // Render the full command when text.name is not 'clones.append' or 'stop'
                    <>
                        <span className="Cws-command-round-box">{selectedObjectName}</span>
                        <span className="Cws-command-name">.{text.name}{"("}</span>
                        {parameters.map((param, index) => (
                            <React.Fragment key={index}>
                                <span className="Cws-command-round-box">{typeof param === 'string' ? `"${param}"` : param}</span>
                                {index < parameters.length - 1 && <span className="Cws-command-comma">,</span>}
                            </React.Fragment>
                        ))}
                        <span className="Cws-command-name">{")"}</span>
                        <CopyToClipboard text={commandText} onCopy={handleCopy}>
                            <FaRegCopy className={`Cws-command-copy-icon ${copied ? 'copied' : ''}`} title="Copy to clipboard" />
                        </CopyToClipboard>
                        <div className="Cws-command-tooltip">{text.description}</div>
                    </>
                )}

            </div>
            {isOpen && (
                <div className="Cws-command-body">
                    <div className="Cws-command-description">
                        <div><strong>{command_in.explain}:</strong> {text.description}</div>
                        <div><strong>{command_in.param}</strong> <pre>{text.parameters_exp}</pre></div>
                        <div><strong>{command_in.return}:</strong> {text.return_exp}</div>
                    </div>
                    <div className="Cws-command-example">
                        <strong>{command_in.example}</strong>
                        <pre>{text.exampleCode}</pre>
                        {text.path && <img src={text.path} alt="Example" />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CwsCommand;
