import React, { useState, useEffect } from 'react';
import { FiPlusCircle } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import { BsSymmetryVertical } from "react-icons/bs";
import { LuMoveHorizontal, LuMoveVertical, LuRotateCw } from "react-icons/lu";
import { FaRotateRight } from "react-icons/fa6";
import { FaRegSquare } from "react-icons/fa6";
import { CgRename } from "react-icons/cg";
import Swal from 'sweetalert2';

import ImageSelectionModal from './Modal_set/ImageSelectionModal';
import RenameModal from './Modal_set/CwsRenameModal';

import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';

import "./CwsObSec.css";

// Importing all images manually
import Blue_golem from '../../img_set/client_icon/Blue_golem.png';
import Blue_witch from '../../img_set/client_icon/Blue_witch.png';
import Castle_Blue from '../../img_set/client_icon/Castle_Blue.png';
import Cherry from '../../img_set/client_icon/Cherry.png';
import Eagle from '../../img_set/client_icon/Eagle.png';
import Fox from '../../img_set/client_icon/Fox.png';
import Frog from '../../img_set/client_icon/Frog.png';
import g_left from '../../img_set/client_icon/g-left.png';
import Gem from '../../img_set/client_icon/Gem.png';
import Golden_chalice from '../../img_set/client_icon/Golden_chalice.png';
import G_Spawn from '../../img_set/client_icon/G_Spawn.png';
import House_Blue from '../../img_set/client_icon/House_Blue.png';
import Jotem from '../../img_set/client_icon/Jotem.png';
import Leaf_ranger from '../../img_set/client_icon/Leaf_ranger.png';
import Luck_creature from '../../img_set/client_icon/Luck_creature.png';
import M_Spawn from '../../img_set/client_icon/M_Spawn.png';
import Nihgt_Knight from '../../img_set/client_icon/Nihgt_Knight.png';
import Orange_golem from '../../img_set/client_icon/Orange_golem.png';
import sheep_left from '../../img_set/client_icon/sheep-left.png';
import star_effect from '../../img_set/client_icon/star_effect.png';
import Stone_tower from '../../img_set/client_icon/Stone_tower.png';
import Tower_Blue from '../../img_set/client_icon/Tower_Blue.png';
import Tree from '../../img_set/client_icon/Tree.png';
import Warrior_Blue from '../../img_set/client_icon/Warrior_Blue.png';
import Wizard from '../../img_set/client_icon/Wizard.png';
import W_Spawn from '../../img_set/client_icon/W_Spawn.png';
import Arrow from '../../img_set/client_icon/arrow.png';
import Time_effect from '../../img_set/client_icon/time_effect.png';
import Time_effect2 from '../../img_set/client_icon/time_effect2.png';
import Time_effect3 from '../../img_set/client_icon/time_effect3.png';

const imgList = [
    { name: 'warrior_blue', src: Warrior_Blue, type: 'Warrior_Blue' },
    { name: 'tree', src: Tree, type: 'Tree' },
    { name: 'wizard_pupple', src: Wizard, type: 'Wizard' },
    { name: 'wood', src: W_Spawn, type: 'Wood' },
    { name: 'blue_golem', src: Blue_golem, type: 'Blue_golem' },
    { name: 'castle_Blue', src: Castle_Blue, type: 'Castle_Blue' },
    { name: 'cherry', src: Cherry, type: 'Cherry' },
    { name: 'eagle', src: Eagle, type: 'Eagle' },
    { name: 'fox', src: Fox, type: 'Fox' },
    { name: 'frog', src: Frog, type: 'Frog' },
    { name: 'goblin', src: g_left, type: 'Goblin' },
    { name: 'gem', src: Gem, type: 'Gem' },
    { name: 'gold_cup', src: Golden_chalice, type: 'Golden_chalice' },
    { name: 'gold', src: G_Spawn, type: 'Gold' },
    { name: 'gouse_blue', src: House_Blue, type: 'House_Blue' },
    { name: 'archer', src: Leaf_ranger, type: 'Leaf_ranger' },
    { name: 'slime', src: Luck_creature, type: 'Luck_creature' },
    { name: 'meat', src: M_Spawn, type: 'Meat' },
    { name: 'ninza', src: Nihgt_Knight, type: 'Nihgt_Knight' },
    { name: 'orange_golem', src: Orange_golem, type: 'Orange_golem' },
    { name: 'sheep', src: sheep_left, type: 'Sheep' },
    { name: 'star', src: star_effect, type: 'Star_effect' },
    { name: 'tower_wood', src: Stone_tower, type: 'Stone_tower' },
    { name: 'tower_blue', src: Tower_Blue, type: 'Tower_Blue' },
    { name: 'arrow', src: Arrow, type: 'Arrow' },
    { name: 'time_effect', src: Time_effect, type: 'Time_effect' },
    { name: 'time_effect2', src: Time_effect2, type: 'Time_effect2' },
    { name: 'time_effect3', src: Time_effect3, type: 'Time_effect3' },
];

const markers = [
    { start: '#@1-0-define_class-0-S', end: '#@1-0-define_class-0-E', indent: 0 },
    { start: '#@1-1-global_var_inF-4-S', end: '#@1-1-global_var_inF-4-E', indent: 4 },
    { start: '#@1-2-draw_inL-8-S', end: '#@1-2-draw_inL-8-E', indent: 8 },
    { start: '#@1-3-user_run-8-S', end: '#@1-3-user_run-8-E', indent: 8 }, //3
    { start: '#@1-4-user_loop-8-S', end: '#@1-4-user_loop-8-E', indent: 8 },
    { start: '#@1-5-event-0-S', end: '#@1-5-event-0-E', indent: 0 },
    { start: '#@0-6-class_init_var-8-S', end: '#@0-6-class_init_var-8-E', indent: 8 },
    { start: '#@0-7-class_func-4-S', end: '#@0-7-class_func-4-E', indent: 4 },
    { start: '#@0-8-user_define_class-0-S', end: '#@0-8-user_define_class-0-E', indent: 0 },
    { start: '#@1-9-user_run_func-0-S', end: '#@1-9-user_run_func-0-E', indent: 0 },
    { start: '#user-add-img-S', end: '#user-add-img-E', indent: 0 },
];

function insertCode(data, marker, userCode) {
    const startIndex = data.indexOf(marker.start);
    const endIndex = data.indexOf(marker.end);

    if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
        throw new Error('Invalid markers or markers not found');
    }

    const beforeCode = data.substring(0, startIndex + marker.start.length);
    const afterCode = data.substring(endIndex);
    const indentSpaces = ' '.repeat(marker.indent || 0);
    const indentedUserCode = userCode.split('\n').map(line => indentSpaces + line).join('\n');
    return beforeCode + '\n' + indentedUserCode + '\n' + afterCode;
}

const isValidPythonIdentifier = (str) => {
    if (str.length === 0) {
        return false;
    }
    const startsWithNumber = /^\d/.test(str);
    const keywords = new Set([
        'False', 'None', 'True', 'and', 'as', 'assert', 'async', 'await', 'break', 'class', 'continue',
        'def', 'del', 'elif', 'else', 'except', 'finally', 'for', 'from', 'global', 'if', 'import',
        'in', 'is', 'lambda', 'nonlocal', 'not', 'or', 'pass', 'raise', 'return', 'try', 'while', 'with', 'yield'
    ]);
    const isKeyword = keywords.has(str);
    const containsInvalidCharacters = !/^[\p{L}\p{N}_]*$/u.test(str);
    const containsSpecialCharacters = /[!@#$%^&*()\-=+\[\]{};':"\\|,.<>\/?]/.test(str);
    const containsWhitespace = /\s/.test(str);
    return !(startsWithNumber || isKeyword || containsInvalidCharacters || containsSpecialCharacters || containsWhitespace);
};

function CwsObSec({ setUserImgDic, project, setProject, selectedSceneIndex, selectedObjectName, setSelectedObjectName }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imgListState, setImgListState] = useState(imgList); // Local state for imgList
    const [newObject, setNewObject] = useState({ name: '', x: 200, y: 200, dir: 0, type: 'Blue_golem', code: [], isMaster: false });
    const [selectedIcon, setSelectedIcon] = useState(imgList[0].src);

    const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);
    const [newName, setNewName] = useState('');

    const { language, setLanguage } = useLanguage();
    const text = texts[language];

    const addUserImageToImgDic = (fileName, url, width, height) => {
        const userImgDicEntry = {
            url:url,
            fileName : fileName,
            width: width,
            height: height,
            code : `
user_${fileName}_img_dic = {
    "default": {
        "frame": 1,
        "f-width-full": ${width},
        "f-width": ${width},
        "f-height": ${height},
        "draw_width": ${width},
        "draw_height": ${height},
        "hit_x": 0,
        "hit_y": 0,
        "hit_w": ${width},
        "hit_h": ${height},
        "img-url": '${url}'
    },
}
    `};
    setProject(prevProject => ({
        ...prevProject,
        userImgDic: [...prevProject.userImgDic, userImgDicEntry]
    }));
    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === "XYD_state") {
                const { name, x, y, dir } = event.data.message;
                setProject(prevProject => {
                    const updatedObjects = { ...prevProject.objects };
                    if (updatedObjects[name]) {
                        updatedObjects[name] = { ...updatedObjects[name], x: parseInt(x), y: parseInt(y), dir: parseInt(dir) };
                    }
                    return { ...prevProject, objects: updatedObjects };
                });
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const generateCodeSkeleton = (name, type) => {
        return {
            define_class: `${name} = ${name}(${type}_img_dic)`,
            event_sec: `async def ${name}_control(e):\n    try:\n        if e.code == 'KeyW':\n            guide_var = "when press W"\n        elif e.code == 'KeyS':\n            guide_var = "when press S"\n        elif e.code == 'KeyA':\n            guide_var = "when press A"\n        elif e.code == 'KeyD':\n            guide_var = "when press D"\n    except Exception as e:\n        errorMessage = str(e)\n        window.sendPyErrorMessage(errorMessage)\ndocument.addEventListener('keydown', create_proxy(${name}_control))`,
            class_define: `class ${name}(EngineDefaultObject):\n    def __init__(self, img_dic):\n        super().__init__(img_dic)\n    def clone(self):\n        new_clone = super().clone()\n        return new_clone\n#@0-6-class_init_var-8-S\n#@0-6-class_init_var-8-E\n#@0-7-class_func-4-S\n#@0-7-class_func-4-E`,
            run_func_define: `async def ${name}_run_func():\n    try:\n        state = 0\n#@1-3-user_run-8-S\n#@1-3-user_run-8-E\n    except Exception as e:\n        errorMessage = str(e)\n        window.sendPyErrorMessage(errorMessage)\nrun_func_set.append(asyncio.create_task(${name}_run_func()))`
        };
    };

    const handleAddObject = (width, height) => {
        if (!isValidPythonIdentifier(newObject.name)) {
            Swal.fire({
                title: "Name error",
                text: "Invalid object name. Please choose a different name.",
                icon: "error"
            });
            return;
        }

        if (project.objects[newObject.name]) {
            Swal.fire({
                title: "Name error",
                text: "Object name already exists. Please choose a different name.",
                icon: "error"
            });
            return;
        }

        const objectType = imgListState.find(img => img.src === selectedIcon)?.type || `user_${newObject.name}`;
        const codeSkeleton = generateCodeSkeleton(newObject.name, objectType);
        const newObj = {
            ...newObject,
            x: 0,
            y: 0,
            dir: 0,
            mirror: 0,
            icon: selectedIcon,
            type: objectType,
            hit_box: 0,
            code: [
                { value: '', isOpen: true, edit: true, show: true }, //0 run init code
                { value: codeSkeleton.event_sec, isOpen: false, edit: true, show: true }, //1 event
                { value: '', isOpen: false, edit: true, show: true }, //2 class func
                { value: codeSkeleton.define_class, isOpen: false, edit: false, show: false }, //3 define_class
                { value: codeSkeleton.class_define, isOpen: false, edit: false, show: false }, //4 class_define
                { value: codeSkeleton.run_func_define, isOpen: false, edit: false, show: false } //5 run func
            ],
            scene_index: newObject.isMaster ? -1 : selectedSceneIndex
        };

        if (objectType.startsWith('user')) {
            addUserImageToImgDic(newObject.name, selectedIcon, width, height);
        }

        setProject(prevProject => ({
            ...prevProject,
            objects: { ...prevProject.objects, [newObj.name]: newObj }
        }));
        setNewObject({ name: '', x: 200, y: 200, dir: 0, code: [], type: 'Blue_golem', isMaster: false });
        setSelectedIcon(imgList[0].src);
        setModalIsOpen(false);
    };

    const handleDeleteObject = (name) => {
        setProject(prevProject => {
            const newObjects = { ...prevProject.objects };
            const objectToDelete = newObjects[name];
            delete newObjects[name];
            if (objectToDelete.type.startsWith('user')) {
                
         

                 setProject(prevProject => ({
                    ...prevProject,
                    userImgDic: prevProject.userImgDic.filter(entry => entry.code.includes(`user_${name}`))
                }));
            }
            return { ...prevProject, objects: newObjects };
        });
        if (selectedObjectName === name) {
            setSelectedObjectName(null);
        }
    };

    const handleObjectClick = (name) => {
        setSelectedObjectName(name);
    };

    const handleRenameObject = () => {
        if (!isValidPythonIdentifier(newName)) {
            Swal.fire({
                title: "Name error",
                text: "Invalid object name. Please choose a different name.",
                icon: "error"
            });
            return;
        }

        if (project.objects[newName]) {
            Swal.fire({
                title: "Name error",
                text: "Object name already exists. Please choose a different name.",
                icon: "error"
            });
            return;
        }

        setProject(prevProject => {
            const updatedObjects = { ...prevProject.objects };
            const objectToRename = updatedObjects[selectedObjectName];
            delete updatedObjects[selectedObjectName];
            updatedObjects[newName] = {
                ...objectToRename,
                name: newName,
                code: [
                    { value: objectToRename.code[0].value, isOpen: true, edit: true, show: true }, //0 run init code
                    { value: generateCodeSkeleton(newName, objectToRename.type).event_sec, isOpen: true, edit: true, show: true }, //3 define_class
                    { value: objectToRename.code[2].value, isOpen: false, edit: true, show: true }, //2 class func
                    { value: generateCodeSkeleton(newName, objectToRename.type).define_class, isOpen: false, edit: false, show: false }, //3 define_class
                    { value: generateCodeSkeleton(newName, objectToRename.type).class_define, isOpen: false, edit: false, show: false }, //4 class_define
                    { value: generateCodeSkeleton(newName, objectToRename.type).run_func_define, isOpen: false, edit: false, show: false } //5 run func
                ]
            };
            return { ...prevProject, objects: updatedObjects };
        });
        setRenameModalIsOpen(false);
        setNewName('');
        Swal.fire({
            title: "Rename Success",
            text: "Check all code and change name too! using Ctrl + F !",
            icon: "success"
        });
    };

    const handleMirrorToggle = () => {
        setProject(prevProject => {
            const updatedObjects = { ...prevProject.objects };
            updatedObjects[selectedObjectName].mirror = updatedObjects[selectedObjectName].mirror === 0 ? 1 : 0;
            return { ...prevProject, objects: updatedObjects };
        });
    };

    const handleHitBox = () => {
        setProject(prevProject => {
            const updatedObjects = { ...prevProject.objects };
            updatedObjects[selectedObjectName].hit_box = updatedObjects[selectedObjectName].hit_box === 0 ? 1 : 0;
            return { ...prevProject, objects: updatedObjects };
        });
    };

    if (!project || !project.objects) {
        return <div>Loading...</div>; // or some other placeholder
    }

    const filteredObjects = Object.values(project.objects).filter(obj => (obj.scene_index === selectedSceneIndex || obj.scene_index === -1) && !obj.type.startsWith('#'));

    return (
        <div className="Cws-ob-con">
            <div className="Cws-ob-detail">
                {selectedObjectName !== null && project.objects[selectedObjectName] ? (
                    <>
                        <div className="Cws-ob-detail-controls">
                            <div className="Cws-ob-control-item">
                                <span>{text.obSec.objectName}</span>
                                <div className="Cws-ob-detail-round-box">{project.objects[selectedObjectName].name}</div>
                            </div>
                            <div className="Cws-ob-control-item">
                                <BsSymmetryVertical className="Cws-ob-detail-icon" size="20" />
                                <label className="Cws-switch">
                                    <input
                                        type="checkbox"
                                        checked={project.objects[selectedObjectName].mirror === 1}
                                        onChange={handleMirrorToggle}
                                    />
                                    <span className="Cws-slider"></span>
                                </label>
                            </div>
                            <div className="Cws-ob-control-item">
                                <FaRegSquare className="Cws-ob-detail-icon" size="20" />
                                <label className="Cws-switch">
                                    <input
                                        type="checkbox"
                                        checked={project.objects[selectedObjectName].hit_box === 1}
                                        onChange={handleHitBox}
                                    />
                                    <span className="Cws-slider"></span>
                                </label>
                            </div>
                        </div>
                        <div className="Cws-ob-detail-controls">
                            <div className="Cws-ob-control-item">
                                <LuMoveHorizontal className="Cws-ob-detail-icon"  />
                                <span>X</span>
                                <div className="Cws-ob-detail-round-box">{project.objects[selectedObjectName].x}</div>
                            </div>
                            <div className="Cws-ob-control-item">
                                <LuMoveVertical className="Cws-ob-detail-icon"  />
                                <span>Y</span>
                                <div className="Cws-ob-detail-round-box">{project.objects[selectedObjectName].y}</div>
                            </div>
                            <div className="Cws-ob-control-item">
                                <span>{text.obSec.direction}</span>
                                <div className="Cws-ob-detail-round-box">{project.objects[selectedObjectName].dir}</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='Cws-ob-init-info'>
                        <div className='Cws-ob-init-info-box'>
                            <FiPlusCircle size="30" />
                            <span style={{ fontFamily: "Inter", fontSize: "14px", marginLeft: "10px" }}>Click! Add Object!</span>
                        </div>
                        <div className='Cws-ob-init-info-box'>
                            <span style={{ fontFamily: "Inter", fontSize: "12px", marginLeft: "10px" }}>When Click Run compile!</span>
                        </div>
                    </div>
                )}
            </div>

            <div className="Cws-ob-main">
                <div className="Cws-ob-main-scroll-wrap">
                    <div className="Cws-ob-list">
                        {filteredObjects.map((object, index) => {
                            let iconSrc = '';
                            if (object.type.startsWith('user')) {
                                iconSrc = object.icon;
                            } else {
                                const imgData = imgListState.find(img => img.type === object.type);
                                iconSrc = imgData ? imgData.src : '';
                            }

                            return (
                                <div
                                    key={object.name}
                                    className={`Cws-ob-box ${selectedObjectName === object.name ? 'active' : ''} ${object.scene_index === -1 ? 'master' : ''}`}
                                    onClick={() => handleObjectClick(object.name)}
                                >
                                    <div className='Cws-ob-img-box'>
                                        <img src={iconSrc} alt={object.name} />
                                    </div>
                                    <TiDeleteOutline
                                        className="Cws-ob-delete-icon"
                                        size="16"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteObject(object.name);
                                        }}
                                    />
                                    {!object.type.startsWith('user') && (
                                        <CgRename
                                            className="Cws-ob-rename-icon"
                                            size="16"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setRenameModalIsOpen(true);
                                            }}
                                        />
                                    )}
                                    <div className="Cws-ob-box-name">
                                        <span style={{ fontFamily: "Inter", fontSize: "14px" }}>{object.name}</span>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="Cws-ob-add-icon" onClick={() => setModalIsOpen(true)}>
                            <FiPlusCircle size="30" />
                        </div>
                    </div>
                </div>
            </div>
            <ImageSelectionModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                imgList={imgListState}
                setImgList={setImgListState}
                selectedIcon={selectedIcon}
                setSelectedIcon={setSelectedIcon}
                newObject={newObject}
                setNewObject={setNewObject}
                handleAddObject={handleAddObject}
            />
            <RenameModal
                isOpen={renameModalIsOpen}
                onRequestClose={() => setRenameModalIsOpen(false)}
                currentName={selectedObjectName}
                newName={newName}
                setNewName={setNewName}
                onRename={handleRenameObject}
            />
        </div>
    );
}

export default CwsObSec;
