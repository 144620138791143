import React from 'react';
import "./TypoMainFooter.css";

function TypoMainFooter() {
    return (
        <div className='Typo-footer-con'>
            <p className='Typo-footer-text'>
                대표자: 임지훈 | 주소: 경상북도 포항시 남구 유동길 30 단비마을 300호 | 사업자등록번호 276-22-01855 [
                <a 
                    href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2762201855" 
                    rel="noopener noreferrer" 
                    target="_blank"
                    className='Typo-footer-link'
                >
                    사업증 확인 
                </a>
                ]    <br></br> | 통신 판매업 신고 번호: 2024-경북포항-0578
            </p>

            <p className='Typo-footer-text'>
                개인정보관리책임자: 임지훈 | 문의: 010-6802-0414 [
                <a 
                    href="mailto:zeroair0071@postech.ac.kr" 
                    className='Typo-footer-link'
                >
                    e-mail:zeroair0071@postech.ac.kr
                </a>
                ]
            </p>

            
        </div>
    );
}

export default TypoMainFooter;
