import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TypoNavBar from './TypoNavBar';
import TypoMainFooter from './TypoMainFooter';
import "./TypoExplorePage.css";

function TypoExplorePage() {
    const ENDPOINT = process.env.REACT_APP_CloudTypeServer || "http://localhost:4000";
    const [works, setWorks] = useState([]);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        fetchWorks(page);
    }, [page]);

    const fetchWorks = async (pageNum) => {
        try {
            const response = await Axios.get(`${ENDPOINT}/api/typo-explore`, { params: { page: pageNum } });
            setWorks(response.data);
        } catch (error) {
            console.error('Error fetching works:', error);
        }
    };

    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(prevPage => prevPage - 1);
        }
    };

    const handleWorkClick = (workId) => {
        navigate(`/typowork/${workId}`);
    };

    return (
        <div className='typo-explore-tot-con'>
            <div className='typo-explore-nav-head'>
                <TypoNavBar />
            </div>
            <div className='typo-explore-con'>

                <div className='typo-explore-head'>
                    <h2>TYPO 작품 공유하기!</h2>
                    <span>가장 Geek 같은 작품을 만들어보세요!</span>

                </div>
                <div className='typo-explore-body'>
                    {works.length > 0 ? (
                        <div className='typo-explore-grid'>
                            {works.map((work, index) => (
                                <div
                                    key={index}
                                    className='typo-explore-card'
                                    onClick={() => handleWorkClick(work._id)}
                                >
                                    <div className='typo-explore-card-image'>
                                        <img src={work.sumimglink} alt={work.title} />
                                    </div>
                                    <div className='typo-explore-card-content'>
                                        <h3>{work.title}</h3>
                                        <p>By {work.user_string_id}</p>
                                        <div className='typo-explore-card-stats'>
                                            <span>❤️ {work.like}</span>
                                            <span>👓 {work.view}</span>
                                            <span>💬 {work.comment_num}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>No works available to display.</div>
                    )}
                </div>
                <div className='typo-explore-button-box'>
                    <button
                        className="typo-custom-button"
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                    >
                        이전
                    </button>
                    <button
                        className="typo-custom-button"
                        onClick={handleNextPage}
                    >
                        더보기
                    </button>
                </div>
                <div className='typo-explore-footer'>

                </div>
            </div>
            <div className='TypoMainPage-body-box'>
                <TypoMainFooter />
            </div>
        </div>
    );
}

export default TypoExplorePage;
