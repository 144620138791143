const commandSideTexts = {
    en: {
        motion: "Motion",
        feature: "Feature",
        return: "Return",
        check: "Check",
        flow: "Flow",
        clone: "Clone",
    },
    ko: {
        motion: "움직임",
        feature: "형태",
        return: "반환",
        check: "확인",
        flow: "흐름",
        clone: "복제",
    },
};

export default commandSideTexts;
