import React from 'react';
import Modal from 'react-modal';
import './CwsProjectModal.css'; // Create a CSS file for styling the modal
import { RiCloseCircleFill } from "react-icons/ri";

const ProjectModal = ({ isOpen, onRequestClose, projectIds, onSelect }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Select a Project"
            className="Cws-project-modal"
            overlayClassName="Cws-project-modal-overlay"
        >
            <h2>Select a Project</h2>
            <div className="Cws-project-modal-list">
                {projectIds.map(project => (
                    <div
                        key={project._id}
                        className="Cws-project-modal-item"
                        onClick={() => onSelect(project._id)}
                    >
                        {project.name || project._id}
                    </div>
                ))}
            </div>
            <div className="Cws-project-close-icon" onClick={onRequestClose}>
                <RiCloseCircleFill size="25" />
            </div>
        </Modal>
    );
};

export default ProjectModal;
