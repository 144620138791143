import React, { useEffect, useRef } from 'react';
import { LuChevronDown, LuChevronUp } from 'react-icons/lu';
import { loader } from '@monaco-editor/react';
import Editor from "@monaco-editor/react";
import './CwsToggleEditor.css'; // Import the CSS file

function CwsToggleEditor({ explainSentance, initialValue, onChange, isOpen, handleToggle }) {
    const editorRef = useRef(null);
    const monacoRef = useRef(null);
    const decorationsRef = useRef([]);

    const options = {
        fontSize: 14
    };

    useEffect(() => {
        loader.init().then((monaco) => {
            monacoRef.current = monaco;
            monaco.editor.defineTheme('myTheme', {
                base: 'light', // Use 'vs-dark' to ensure it inherits from the dark theme
                inherit: true,
                rules: [],
                colors: {
                    'editor.background': '#1a1d31',
                },
            });
        }).catch(error => {
            console.error("Error loading Monaco editor:", error);
        });
    }, []);

    const checkForErrors = () => {
        const editor = editorRef.current;
        const monaco = monacoRef.current;

        if (!editor || !monaco) return;

        const model = editor.getModel();
        if (!model) return;

        const text = model.getValue();
        const lines = text.split('\n');
        const errorLines = new Set();

        lines.forEach((line, index) => {
            const trimmedLine = line.trim();
            if (
                (trimmedLine.startsWith('for') ||
                    trimmedLine.startsWith('while') ||
                    trimmedLine.startsWith('if') ||
                    trimmedLine.startsWith('def') ||
                    trimmedLine.startsWith('class')) &&
                !trimmedLine.endsWith(':')
            ) {
                errorLines.add(index + 1); // +1 because Monaco is 1-indexed
            }
        });

        const newDecorations = Array.from(errorLines).map((lineNumber) => ({
            range: new monaco.Range(lineNumber, 1, lineNumber, model.getLineMaxColumn(lineNumber)),
            options: {
                isWholeLine: true,
                className: 'error-line',
                hoverMessage: [{ value: '**Error**: Missing colon (:) at the end of this line.' }]
            }
        }));

        // Apply new decorations and save references
        decorationsRef.current = editor.deltaDecorations(decorationsRef.current, newDecorations);
    };

    useEffect(() => {
        if (editorRef.current && monacoRef.current) {
            const editor = editorRef.current;
            const model = editor.getModel();
            if (model && initialValue !== model.getValue()) {
                model.setValue(initialValue);
                checkForErrors(); // Ensure error check is run after setting new value
            }
        }
    }, [initialValue]);

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
        monacoRef.current = monaco;

        checkForErrors();

        editor.onDidChangeModelContent(() => {
            checkForErrors();
        });
    };

    return (
        <div className="Cws-toggle-con">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: "10px", paddingLeft: "5px", marginTop: "5px", marginBottom: "5px" }}>
                <span>{explainSentance}</span>
                <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
                    {isOpen ? <LuChevronUp size="16" className="Cws-toggle-icon" /> : <LuChevronDown size="16" className="Cws-toggle-icon" />}
                </div>
            </div>
            {isOpen && (
                <div className="Cws-toggle-editor-con">
                    <Editor
                        className="monaco-editor"
                        defaultLanguage="python"
                        value={initialValue}
                        onChange={(value) => onChange(value)}
                        height="100%"
                        width="100%"
                        theme="light"
                        options={options}
                        onMount={handleEditorDidMount}
                    />
                </div>
            )}
        </div>
    );
}

export default CwsToggleEditor;
