import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import useStore from '../GlobalStore';
import './TypoWorkExPage.css';
import CwsResultPage from "../CODENAME_WORK_SPACE/CwsResultPage";
import TypoMainFooter from './TypoMainFooter';
import Swal from 'sweetalert2';
import TypoNavBar from './TypoNavBar';

function TypoWorkExPage() {
    const { isLoggedIn, userName, logout, userId, userStringId } = useStore();
    const { workId } = useParams();
    const ENDPOINT = process.env.REACT_APP_CloudTypeServer || "http://localhost:4000";
    const [Exproject, setExProject] = useState({});
    const [ExuserBgDic, setExuserBgDic] = useState([]);
    const [ExuserImgDic, setExuserImgDic] = useState([]);
    const [ExRunURL, setExRunURL] = useState("");
    const [sumImg, setSumImg] = useState("");
    const [likes, setLikes] = useState(0);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [hasLiked, setHasLiked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const response = await Axios.get(`${ENDPOINT}/api/project/work/${workId}`);
                const { project, userBgDic = [], userImgDic = [], sumImg, like, comments } = response.data;
                setExProject(project);
                setExuserBgDic(userBgDic);
                setExuserImgDic(userImgDic);
                setSumImg(sumImg);
                setLikes(like);
                setComments(comments || []);
                incrementView(); // Increment the view count
            } catch (error) {
                console.error('Failed to fetch project details:', error);
            }
        };

        fetchProjectDetails();
    }, [workId, ENDPOINT]);

    const incrementView = async () => {
        const lastViewTime = localStorage.getItem(`lastViewTime_${workId}_${userId}`);
        const now = new Date();
        const viewCooldown = 10 * 60 * 1000; // 10 minutes cooldown

        if (!lastViewTime || now - new Date(lastViewTime) >= viewCooldown) {
            try {
                await Axios.post(`${ENDPOINT}/api/typowork/${workId}/view`, { userId });
                localStorage.setItem(`lastViewTime_${workId}_${userId}`, now.toISOString());
            } catch (error) {
                console.error('Failed to increment view count:', error);
            }
        }
    };

    const handleRunClick = () => {
        setExRunURL('frontend_run');
    };

    const handleReviseClick = () => {
        const revisedProject = {
            ...Exproject,
            name: `${Exproject.name}-revise`,
            description: '',
        };
        navigate('/compiler', { state: { project: revisedProject } });
    };

    const handleLikeClick = async () => {
        if (!isLoggedIn) {
            Swal.fire({
                title: "You have to login",
                icon: "error"
            });
            return;
        }
        if (hasLiked) return;

        try {
            const response = await Axios.post(`${ENDPOINT}/api/typowork/${workId}/like`, { userId });
            setLikes(response.data.like);
            setHasLiked(true);
        } catch (error) {
            console.error('Failed to like work:', error);
        }
    };

    const handleCommentSubmit = async () => {
        if (!isLoggedIn) {
            Swal.fire({
                title: "You have to login",
                icon: "error"
            });
            return;
        }
        if (!newComment) return;

        try {
            const response = await Axios.post(`${ENDPOINT}/api/typowork/${workId}/comment`, {
                userId,
                text: newComment,
                userStringId,
            });
            setComments(response.data.comments);
            setNewComment(""); // Clear the comment input
        } catch (error) {
            console.error('Failed to add comment:', error);
        }
    };

    return (
        <div className='typo-work-ex-wrap'>
            <div className='typo-work-ex-nav-head'>
                <TypoNavBar />
            </div>
            <div className='typo-work-ex-con'>
                <div className='typo-work-ex-head'>
                    <h2>{Exproject.name || "작품 제목"}</h2>
                </div>
                <div className='typo-work-ex-body'>
                    <div className='typo-work-ex-result-con'>
                        <div className='typo-work-ex-result-iframe'>
                            {ExRunURL ? (
                                <CwsResultPage
                                    userBgDic={ExuserBgDic}
                                    userImgDic={ExuserImgDic}
                                    project={Exproject}
                                    setProject={setExProject}
                                    RunURL={ExRunURL}
                                    setSumImg={setSumImg}
                                />
                            ) : (
                                <img src={sumImg} alt="Project Thumbnail" className="typo-work-ex-thumbnail" />
                            )}
                        </div>
                        <div className='typo-work-ex-result-script'>
                            <p>{Exproject.description || "작품 설명"}</p>
                        </div>
                    </div>
                    <div className='typo-work-ex-buttons'>
                        <button onClick={handleRunClick} className="typo-work-ex-run-button">Run</button>
                        <button onClick={handleReviseClick} className="typo-work-ex-revise-button">Revise in Compiler</button>
                        <button onClick={handleLikeClick} className="typo-work-ex-like-button">Like {likes}</button>
                    </div>
                    <div className='typo-work-ex-comment-box'>
                        <div className='typo-work-ex-comment-box-list'>
                            {comments.map((comment, index) => (
                                <div key={index} className='typo-work-ex-comment'>
                                    <strong>{comment.user_string_id}</strong>: {comment.text}
                                </div>
                            ))}
                        </div>
                        {isLoggedIn ? (
                            <div className='typo-work-ex-comment-input'>
                                <input
                                    type="text"
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    placeholder="Add a comment"
                                />
                                <button onClick={handleCommentSubmit}>Submit</button>
                            </div>
                        ) : (
                            <div className='typo-work-ex-comment-login-message'>
                                <p>You must be logged in to comment.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='typo-explore-footer'>

                </div>
            </div>
            <div className='TypoMainPage-body-box'>
                <TypoMainFooter />
            </div>
        </div>
    );
}

export default TypoWorkExPage;
