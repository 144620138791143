const mainTexts = {
    en: {
        creating: "Creating",
        learning: "Learning",
        scene: "Scene",
    },
    ko: {
        creating: "창작하기",
        learning: "배우기",
        scene: "장면",
    },
};

export default mainTexts;
