import React, { useState } from 'react';
import Modal from 'react-modal';
import { CiImageOn } from "react-icons/ci";
import './AddBackgroundModal.css';
import { TbTower } from "react-icons/tb";

const AddBackgroundModal = ({ isOpen, onRequestClose, onAddBackground }) => {
    const [name, setName] = useState('');
    const [isMaster, setIsMaster] = useState(false);

    const handleAdd = () => {
        if (name.trim() !== '') {
            onAddBackground(name, isMaster);
            setName('');
            setIsMaster(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Add Background Modal"
            className="add-background-modal"
            overlayClassName="add-background-overlay"
        >
            <span style={{ fontFamily: "Inter", fontSize: "15px", marginRight: "2px", marginLeft: "5px" }}>Add New BackGround</span>
            <div className="add-background-modal-input-group">
                <CiImageOn size={25} />
                <input
                    type="text"
                    placeholder="Background Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="add-background-modal-input-group">
                <TbTower size={15} />
                <span style={{ fontFamily: "Inter", fontSize: "12px", marginRight: "2px", marginLeft: "5px" }}>Master Oject</span>


                <input
                    type="checkbox"
                    checked={isMaster}
                    onChange={() => setIsMaster(!isMaster)}
                    style={{ display: "inline" }}
                />

            </div>
            <div className="add-background-modal-button-group">
                <button onClick={handleAdd} className="add-background-modal-add-button">Add</button>
                <button onClick={onRequestClose} className="add-background-modal-cancel-button">Cancel</button>
            </div>
        </Modal>
    );
};

export default AddBackgroundModal;
