import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import CwsMain from './CODENAME_WORK_SPACE/CwsMain';
import TypoMainPage from './TypoMainPage/TypoMainPage';
import TypoLoginPage from './TypoMainPage/TypoLoginPage';
import TypoWorkExPage from './TypoMainPage/TypoWorkExPage';
import TypoExplorePage from './TypoMainPage/TypoExplorePage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TypoMainPage />} />
        <Route path="/login" element={<TypoLoginPage />} />
        <Route path="/compiler" element={<CwsMain />} />
        <Route path="/compiler/:stage/:dev" element={<CwsMain />} />
        <Route path="/typoexplore" element={<TypoExplorePage/>}/>
        <Route path="/typowork/:workId" element={<TypoWorkExPage />} /> {/* New Route for TypoWorkExPage */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
