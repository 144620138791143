import React, { useState, useEffect } from 'react';
import './CwsLeftSec.css';
import { FaBookOpen } from "react-icons/fa";
import { RiGameFill } from "react-icons/ri";
import CwsNotionView from './CwsNotionView';
import CwsToggleEditor from './CwsToggleEditor';
import { AiOutlineGlobal } from "react-icons/ai";
import { IoLeafOutline } from "react-icons/io5";
import { RiQuillPenLine } from "react-icons/ri";
import CwsCommandEx from './CwsCommandEx';
import Swal from 'sweetalert2';

import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';

function CwsLeftSec({  project, setProject, selectedObjectName }) {
    const [activeTab, setActiveTab] = useState('board');
    const [newSceneName, setNewSceneName] = useState('');

    const { language } = useLanguage();
    const text = texts[language];

    const notionURLs = {
        en: [
            "https://www.notioniframe.com/notion/2dfkmkq5gy2"
        ],
        ko: [
            "https://www.notioniframe.com/notion/1f9u7vb8iifl",
        ]
    };

    const notionURL = notionURLs[language];

 


    const renderContent = () => {
        if (activeTab === 'board') {
            return <div className="Cws-left-sec-tab-content Cws-left-sec-board-content">
                <CwsCommandEx
                    selectedObjectName={selectedObjectName} 
                />
            </div>;
        } else if (activeTab === 'board2') {
            return <div className="Cws-left-sec-tab-content Cws-left-sec-board-content"><CwsNotionView notionURL={notionURL[0]} /></div>;
        }
    };

    return (
        <div className={`Cws-left-sec-con language-${language}`}>
            <div className="Cws-left-sec-head">
                <div
                    className={`Cws-left-sec-tab ${activeTab === 'board' ? 'Cws-left-sec-active' : ''}`}
                    onClick={() => setActiveTab('board')}
                >
                    <FaBookOpen size={20} />
                    <span className="language-dependent-text" style={{ marginLeft: "10px" }}>{text.leftSec.command}</span>
                </div>
                <div
                    className={`Cws-left-sec-tab ${activeTab === 'board2' ? 'Cws-left-sec-active' : ''}`}
                    onClick={() => setActiveTab('board2')}
                >
                    <RiQuillPenLine size={20} />
                    <span className="language-dependent-text" style={{ marginLeft: "10px" }}>{text.leftSec.learning}</span>
                </div>
               
            </div>
            <div className="Cws-left-sec-body">
                {renderContent()}
            </div>
        </div>
    );
}

export default CwsLeftSec;
