import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { RiCloseCircleFill } from "react-icons/ri";
import './CwsLoadModal.css';

const CwsLoadModal = ({ isOpen, onRequestClose, classList, chapters, onSelect, filterType }) => {
    const [selectedClass, setSelectedClass] = useState(null);
    const [openChapter, setOpenChapter] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    useEffect(() => {
        setSelectedClass(null);
        setOpenChapter(null);
        setSelectedProjectId(null); // Reset selection when modal opens
    }, [isOpen]);

    const handleClassSelect = (cls) => {
        setSelectedClass(cls);
        setOpenChapter(null); // Reset chapter when class changes
        setSelectedProjectId(null); // Reset project when class changes
    };

    const handleChapterToggle = (chapter) => {
        setOpenChapter(openChapter === chapter ? null : chapter);
        setSelectedProjectId(null); // Reset project when chapter changes
    };

    const handleProjectSelect = (project) => {
        setSelectedProjectId(project._id); // Use _id for project identification
    };

    const handleLoadProject = () => {
        if (selectedProjectId) {
            onSelect(selectedProjectId); // Load the selected project by _id
            onRequestClose(); // Close the modal
        }
    };

    const filteredClassList = filterType === 'load' ? classList.filter(cls => cls.startsWith('Class')) : classList.filter(cls => cls === 'making');
    const filteredChapters = filterType === 'load' ? chapters.filter(chapter => chapter.name.startsWith('Class')) : chapters.filter(chapter => chapter.name === 'free');

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Load Project"
            className="Cws-load-modal"
            overlayClassName="Cws-load-overlay"
        >
            <div className="Cws-load-sidebar">
                <h3>Select Class</h3>
                <div className="Cws-load-class-container">
                    {filteredClassList.map((cls, index) => (
                        <div
                            key={index}
                            className={`Cws-load-class-box ${selectedClass === cls ? 'active' : ''}`}
                            onClick={() => handleClassSelect(cls)}
                        >
                            {cls}
                        </div>
                    ))}
                </div>
                <button className="Cws-load-load-button" onClick={handleLoadProject}>Load</button>
            </div>
            <div className="Cws-load-content">
                <h3>Select Chapter & Project</h3>
                {filteredChapters
                    .filter(chapter => chapter.name === selectedClass || (selectedClass === 'making' && chapter.name === 'free'))
                    .map((chapter, index) => (
                        <div key={index}>
                            {chapter.chapters.map((chapterDetail, chapIndex) => (
                                <div key={chapIndex}>
                                    <div
                                        className="Cws-load-chapter-toggle"
                                        onClick={() => handleChapterToggle(chapterDetail.name)}
                                    >
                                        {chapterDetail.name}
                                    </div>
                                    {openChapter === chapterDetail.name && (
                                        <div className="Cws-load-projects">
                                            {chapterDetail.projects.map((project, projIndex) => (
                                                <div
                                                    key={projIndex}
                                                    className={`Cws-load-project-box ${selectedProjectId === project._id ? 'active' : ''}`}
                                                    onClick={() => handleProjectSelect(project)}
                                                >
                                                    {project.name}: {project.description}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
            <div className="Cws-load-close-icon" onClick={onRequestClose}>
                <RiCloseCircleFill size="25" />
            </div>
        </Modal>
    );
};

export default CwsLoadModal;
