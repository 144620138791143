import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CwsCodeEditor from "./CwsCodeEditor";
import CwsRightSec from "./CwsRightSec";
import CwsLeftSec from './CwsLeftSec';
import CwsNavBar from './CwsNavBar';
import Axios from 'axios';
import "./CwsMain.css";
import Swal from 'sweetalert2';
import { Resizable } from 're-resizable';
import { v4 as uuidv4 } from 'uuid'; // uuid 패키지 import
import Warrior_Blue from '../../img_set/client_icon/Warrior_Blue.png';

const markers = [
    { start: '#@1-0-define_class-0-S', end: '#@1-0-define_class-0-E', indent: 0 },
    { start: '#@1-1-global_var_inF-4-S', end: '#@1-1-global_var_inF-4-E', indent: 4 },
    { start: '#@1-2-draw_inL-8-S', end: '#@1-2-draw_inL-8-E', indent: 8 },
    { start: '#@1-3-user_run-8-S', end: '#@1-3-user_run-8-E', indent: 8 }, //3
    { start: '#@1-4-user_loop-8-S', end: '#@1-4-user_loop-8-E', indent: 8 },
    { start: '#@1-5-event-0-S', end: '#@1-5-event-0-E', indent: 0 },
    { start: '#@0-6-class_init_var-8-S', end: '#@0-6-class_init_var-8-E', indent: 8 },
    { start: '#@0-7-class_func-4-S', end: '#@0-7-class_func-4-E', indent: 4 },
    { start: '#@0-8-user_define_class-0-S', end: '#@0-8-user_define_class-0-E', indent: 0 },
    { start: '#@1-9-user_run_func-0-S', end: '#@1-9-user_run_func-0-E', indent: 0 },
    { start: '#user-add-img-S', end: '#user-add-img-E', indent: 0 },
];

const extractAsyncFunctions = (code) => {
    const asyncKeywords = ['sleep', 'move_step']; // Keywords considered as asynchronous calls
    const functionPattern = /def\s+([A-Za-z가-힣_][A-Za-z0-9가-힣_]*)\s*\(.*?\)\s*:[\s\S]*?(?=def\s+[A-Za-z가-힣_][A-Za-z0-9가-힣_]*\s*\(|$)/g;
    const asyncFunctions = [];

    // Analyze each function block and collect function names that are considered asynchronous
    code.replace(functionPattern, (match, functionName, offset, string) => {
        // Check if the beginning of the current function definition is 'async def'
        const precedingText = string.slice(Math.max(0, offset - 10), offset).trim();

        // Add to asyncFunctions only if it doesn't start with 'async', contains an async keyword, and doesn't start with 'async def'
        if (!precedingText.startsWith('async') && 
            asyncKeywords.some(keyword => new RegExp(`\\b${keyword}\\b`).test(match))) {
            asyncFunctions.push(functionName);
        }
        return match;
    });

    return asyncFunctions;
};
// 함수 정의를 async로 변환
const convertToAsyncFunction = (code, asyncFunctions) => {
    return code.replace(/def\s+([\w가-힣_]+)\s*\(/g, (match, functionName) => {
        if (asyncFunctions.includes(functionName)) {
            return `async def ${functionName}(`;
        }
        return match;
    });
};

// 비동기 함수 호출에 await 추가 (함수 정의 및 클래스 메서드 정의 부분 제외)
const addAwaitToAsyncFunctionCalls = (code, asyncFunctions) => {
    return code.replace(/(\b[\w가-힣_]+\.\w+|\b[\w가-힣_]+)\(([^)]*)\)/g, (match, functionName, args, offset, string) => {
        // 함수 호출 전에 특정 키워드가 있는지 확인
        const precedingCode = string.slice(0, offset).trim();
        const isFunctionDefinition = precedingCode.endsWith('def');
        const isClassMethod = precedingCode.endsWith('class');
        const hasDecorator = precedingCode.endsWith('@');

        // 함수 정의, 클래스 정의, 데코레이터가 아닌 경우에만 await 추가
        if (!isFunctionDefinition && !isClassMethod && !hasDecorator && 
            (asyncFunctions.includes(functionName) || /sleep|move_step/.test(functionName))) {
            return `await ${functionName}(${args})`;
        }
        return match;
    });
};

const addSleepToLoops = (code) => {
    const whileLoopPattern = /(\s*)while\s+.*:/g;
    const forLoopPattern = /(\s*)for\s+.*:/g;

    // `while` 루프에 `asyncio.sleep(0.0001)` 추가
    code = code.replace(whileLoopPattern, (match, indent) => {
        return `${match}\n${indent}    await asyncio.sleep(0.0001)`;
    });

    // `for` 루프에 `asyncio.sleep(0.0001)` 추가
    code = code.replace(forLoopPattern, (match, indent) => {
        return `${match}\n${indent}    await asyncio.sleep(0.0001)`;
    });

    return code;
};

// 전체 코드를 처리하는 함수
const processCode = (code) => {
    // 비동기 함수 목록 추출
    const asyncFunctions = extractAsyncFunctions(code);

    // 각 함수 블록을 처리
    let updatedCode = convertToAsyncFunction(code, asyncFunctions);

    // 비동기 함수 호출에 await 추가 (함수 정의 부분 제외)
    updatedCode = addAwaitToAsyncFunctionCalls(updatedCode, asyncFunctions);

    // 반복문에 asyncio.sleep(0.0001) 추가
    updatedCode = addSleepToLoops(updatedCode);
    

    return updatedCode;
};



const updateGetSceneIndexCalls = (code, selectedSceneIndex) => {
    const getSceneIndexPattern = /get_scene_index\(\)/g;
    return code.replace(getSceneIndexPattern, `get_scene_index(${selectedSceneIndex});`);
};

const updatePrintCalls = (code) => {
    const printPattern = /print\(([^)]+)\)/g;
    return code.replace(printPattern, 'print_codename($1)');
};

function insertCode(data, marker, userCode) {
    const startIndex = data.indexOf(marker.start);
    const endIndex = data.indexOf(marker.end);

    if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
        throw new Error('Invalid markers or markers not found');
    }

    const beforeCode = data.substring(0, startIndex + marker.start.length);
    const afterCode = data.substring(endIndex);
    const indentSpaces = ' '.repeat(marker.indent || 0);
    const indentedUserCode = userCode.split('\n').map(line => indentSpaces + line).join('\n');
    return beforeCode + '\n' + indentedUserCode + '\n' + afterCode;
}

function checkForInfiniteLoops(code) {
    const whileLoopPatterns = [
        /while\s+True\s*:/,
        /while\s+1\s*:/,
        /while\s+\(true\)\s*:/i,
    ];

    const finiteForLoopPattern = /for\s+.*\s+in\s+range\s*\(\s*\d+\s*(,\s*\d+\\s*)?(,\s*\d+\\s*)?\)\s*:/;
    const infiniteForLoopPattern = /for\s+.*\s+in\s+range\s*\(\s*\)\s*:/;

    const lines = code.split('\n');
    let inLoop = false;
    let loopCode = '';
    let indentLevel = 0;

    for (const line of lines) {
        const trimmedLine = line.trim();

        if (whileLoopPatterns.some(pattern => pattern.test(trimmedLine)) || infiniteForLoopPattern.test(trimmedLine)) {
            inLoop = true;
            loopCode = '';
            indentLevel = line.search(/\S|$/);
        } else if (finiteForLoopPattern.test(trimmedLine)) {
            inLoop = false;
        }

        if (inLoop) {
            loopCode += line + '\n';

            const currentIndentLevel = line.search(/\S|$/);
            if (currentIndentLevel <= indentLevel && trimmedLine === '') {
                inLoop = false;
                if (!loopCode.includes('sleep(')) {
                    return true;
                }
            }
        }
    }

    return false;
}

const generateCodeSkeleton = (name, type) => {
    return {
        define_class: `${name} = ${name}(${type}_img_dic)`,
        event_sec: `async def ${name}_control(e):\n    try:\n        if e.code == 'KeyW':\n            guide_var = "when press W"\n        elif e.code == 'KeyS':\n            guide_var = "when press S"\n        elif e.code == 'KeyA':\n            guide_var = "when press A"\n        elif e.code == 'KeyD':\n            guide_var = "when press D"\n    except Exception as e:\n        errorMessage = str(e)\n        window.sendPyErrorMessage(errorMessage)\ndocument.addEventListener('keydown', create_proxy(${name}_control))`,
        class_define: `class ${name}(EngineDefaultObject):\n    def __init__(self, img_dic):\n        super().__init__(img_dic)\n    def clone(self):\n        new_clone = super().clone()\n        return new_clone\n#@0-6-class_init_var-8-S\n#@0-6-class_init_var-8-E\n#@0-7-class_func-4-S\n#@0-7-class_func-4-E`,
        run_func_define: `async def ${name}_run_func():\n    try:\n        state = 0\n#@1-3-user_run-8-S\n#@1-3-user_run-8-E\n    except Exception as e:\n        errorMessage = str(e)\n        window.sendPyErrorMessage(errorMessage)\nrun_func_set.append(asyncio.create_task(${name}_run_func()))`
    };
};

function CwsMain() {
    
    const [RunURL, setRunURL] = useState("");
    const [loading, setLoading] = useState(false);
    
    const [selectedObjectName, setSelectedObjectName] = useState('HERO');
    const [selectedSceneIndex, setSelectedSceneIndex] = useState(0); // Added state for scene index
    const [resultPageKey, setResultPageKey] = useState(0);
    const [reImgLink, setReImgLink] = useState(""); 
    const [logs, setLogs] = useState([]); // Add state for logs
    const [sumImg,setSumImg] = useState("");
    
    const location = useLocation();
    const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId') || '');
    

    const defaultObjectName = 'HERO';
    const defaultObjectType = 'Warrior_Blue';
    const defaultObjectSkeleton = generateCodeSkeleton(defaultObjectName, defaultObjectType);

    const defaultProject = {
        name: 'My Project',
        description: 'Project Description',
        objects: {
            [defaultObjectName]: {
                name: defaultObjectName,
                x: 0,
                y: 0,
                dir: 0,
                mirror: 0,
                icon: Warrior_Blue, // Ensure this matches the correct icon
                type: defaultObjectType,
                hit_box: 0,
                code: [
                    { value: 'HERO.say("Hello")', isOpen: true, edit: true, show: true }, //0 run init code
                    { value: defaultObjectSkeleton.event_sec, isOpen: false, edit: true, show: true }, //1 event
                    { value: '', isOpen: false, edit: true, show: true }, //2 class func
                    { value: defaultObjectSkeleton.define_class, isOpen: false, edit: false, show: false }, //3 define_class
                    { value: defaultObjectSkeleton.class_define, isOpen: false, edit: false, show: false }, //4 class_define
                    { value: defaultObjectSkeleton.run_func_define, isOpen: false, edit: false, show: false } //5 run func
                ],
                scene_index: 0 // Assuming it's for the main scene
            }
        },
        masterCode: [
            { value: '', isOpen: true, edit: true, show: true }, // init code 0
        ],
        compileCode: [
            { value: '', isOpen: false, edit: false, show: false }, // init code 0
            { value: '', isOpen: false, edit: false, show: false }, // event 1
            { value: '', isOpen: false, edit: false, show: false }, // class func 2
            { value: '', isOpen: false, edit: false, show: false }, // define_class 3
            { value: '', isOpen: false, edit: false, show: false }, // class_define 4
            { value: '', isOpen: false, edit: false, show: false }, // run func 5
            { value: '', isOpen: false, edit: false, show: false }, // backGround 6
            { value: '', isOpen: false, edit: false, show: false }, // master code 7
        ],
        scenes: [{ name: 'Main Scene', index: 0 }],

        userBgDic :[],
        userImgDic : [],
        userBgDic_url : [],
        userImgDic_url : []
    }

    const savedProject = JSON.parse(localStorage.getItem('currentProject'));

    const [project, setProject] = useState(location.state?.project || savedProject || defaultProject);

    

    useEffect(() => {
        if (!sessionId) {
            const newSessionId = uuidv4();
            setSessionId(newSessionId);
            localStorage.setItem('sessionId', newSessionId);
        }
    }, [sessionId]);

    useEffect(() => {
        handleCompileRun();
        const firstObject = Object.keys(project.objects).find(
            (key) => project.objects[key].scene_index === selectedSceneIndex || project.objects[key].scene_index === -1
        );
        setSelectedObjectName(firstObject || '');
    }, [selectedSceneIndex]);
    
    useEffect(() => {
        // Save the project state to localStorage whenever it changes
        localStorage.setItem('currentProject', JSON.stringify(project));
    }, [project]);

    function handleCompileRun() {
        const validObjectsCount = Object.values(project.objects).filter(
            (obj) => (obj.scene_index === selectedSceneIndex || obj.scene_index === -1) && !obj.type.startsWith('#')
        ).length;

        if (validObjectsCount === 0) {
            Swal.fire({
                title: "Object Needed!",
                text: "Please create at least one object before running.",
                icon: "warning",
                theme: "Bluma"
            });
            return;
        }

        setLoading(true);
        setRunURL('');
        setLogs([]); // Clear logs on compile run

        let compileValues = Array(8).fill('').map(() => '');

        const objectsCompile = Object.values(project.objects).filter(obj => !obj.type.startsWith('#') && (obj.scene_index === selectedSceneIndex || obj.scene_index === -1));
        objectsCompile.forEach(obj => {
            compileValues[0] += `${obj.name}.set_init_state("${obj.name}", ${obj.mirror}, ${obj.hit_box})\n` + updateGetSceneIndexCalls(updatePrintCalls(obj.code[0].value), selectedSceneIndex) + '\n';
            compileValues[1] += updateGetSceneIndexCalls(processCode(updatePrintCalls(obj.code[1].value)), selectedSceneIndex) + '\n';
            compileValues[2] += updateGetSceneIndexCalls(updatePrintCalls(obj.code[2].value), selectedSceneIndex) + '\n';
            compileValues[3] += updateGetSceneIndexCalls(updatePrintCalls(obj.code[3].value), selectedSceneIndex) + '\n' + `world_all_objects.append(${obj.name})\n`;
            compileValues[4] += updateGetSceneIndexCalls(updatePrintCalls(obj.code[4].value), selectedSceneIndex) + '\n';
            compileValues[5] += updateGetSceneIndexCalls(updatePrintCalls(insertCode(obj.code[5].value, markers[3], `${obj.name}.set_init_state("${obj.name}", ${obj.mirror}, ${obj.hit_box})\n` + processCode(updatePrintCalls(obj.code[0].value)))), selectedSceneIndex) + '\n';
        });

        const backgroundsCompile = Object.values(project.objects).filter(obj => obj.type.startsWith('#') && (obj.scene_index === selectedSceneIndex || obj.scene_index === -1));
        backgroundsCompile.forEach(bg => {
            console.log(bg);
            compileValues[6] += bg.code.map(code => updateGetSceneIndexCalls(updatePrintCalls(code.value), selectedSceneIndex)).join('\n') + '\n';
        });

        compileValues[7] = project.masterCode.map(code => updateGetSceneIndexCalls(updatePrintCalls(code.value), selectedSceneIndex)).join('\n');

        const updatedCompileCode = compileValues.map((value, index) => ({
            value,
            isOpen: false,
            edit: false,
            show: false
        }));

        const updatedProject = { ...project, compileCode: updatedCompileCode };

        

        setProject(updatedProject);

        setLoading(false);
        setRunURL('frontend_run');
        setResultPageKey(prevKey => prevKey + 1);
    }

    function handleRunReset() {
        setRunURL('');
        setLogs([]); // Clear logs on reset
    }

    return (
        <div className="Cws-main-con">
            <div className="Cws-main-head">
                <CwsNavBar
                    project={project}
                    setProject={setProject}
                    handleCompileRun={handleCompileRun}
                    setSelectedObjectName={setSelectedObjectName}
                    sumImg= {sumImg} 
                />
            </div>
            <div className="Cws-main-body">
                <Resizable
                    defaultSize={{
                        width: '36%',
                        height: '100%',
                    }}
                    minWidth="20%"
                    maxWidth="60%"
                    enable={{ right: true }}
                    className="Cws-main-left"
                >
                    <CwsLeftSec
                        project={project}
                        setProject={setProject}
                        selectedObjectName={selectedObjectName}
                    />
                </Resizable>
                <div style={{ flexGrow: 1 }} className="Cws-main-center">
                    <CwsCodeEditor
                        selectedSceneIndex={selectedSceneIndex}
                        setSelectedSceneIndex={setSelectedSceneIndex}
                        RunURL={RunURL}
                        setRunURL={setRunURL}
                        project={project}
                        setProject={setProject}
                        selectedObjectName={selectedObjectName}
                        setLoading={setLoading}
                        handleCompileRun={handleCompileRun}
                        handleRunReset={handleRunReset} // Pass reset handler
                    />
                </div>
                <div className="Cws-main-right">
                    <CwsRightSec
              
                        RunURL={RunURL}
                        setRunURL={setRunURL}
                        project={project}
                        setProject={setProject}
                        selectedObjectName={selectedObjectName}
                        setSelectedObjectName={setSelectedObjectName}
                        selectedSceneIndex={selectedSceneIndex}
                        loading={loading}
                        handleCompileRun={handleCompileRun}
                        Rekey={resultPageKey} // Pass the key
                        logs={logs} // Pass the logs
                        setLogs={setLogs} // Pass setLogs function
                        
                        setSumImg ={setSumImg}
                    />
                </div>
            </div>
        </div>
    );
}

export default CwsMain;
