import create from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
  persist(
    (set) => ({
      isLoggedIn: false,
      userName: "",
      userId: "",
      userStringId:"",
      userType: 0, // 0 for student, 1 for teacher

      // Actions to update state
      setLoginState: (loginState) => set({ isLoggedIn: loginState }),
      setUserName: (name) => set({ userName: name }),
      setUserStringId: (id) => set({ userStringId: id }),
      setUserId: (id) => set({ userId: id }),
      setUserType: (type) => set({ userType: type }),
      logout: () => set({ isLoggedIn: false, userName: '', userId: '', userType: 0 }),
    }),
    {
      name: "user-store", // unique name for localStorage
      getStorage: () => localStorage, // use localStorage by default
    }
  )
);

export default useStore;
