const bgSecTexts = {
    en: {
        bgName: "BackGround",
    },
    ko: {
        bgName: "배경 이름",
    },
};

export default bgSecTexts;
