import { initializeApp, getApp, getApps } from "firebase/app";
import { getStorage } from "firebase/storage";



// 파이어베이스 Config
const firebaseConfig = {
  
  authDomain: process.env.REACT_APP_FireBaseAuthDomain,
  projectId: process.env.REACT_APP_FireBaseProjectId,
  storageBucket: process.env.REACT_APP_FireBaseStorageBucket,
  messagingSenderId: process.env.REACT_APP_FireBaseMessagingSenderId,
  appId: process.env.REACT_APP_FireBaseAppId,
  measurementId: process.env.REACT_APP_FireBaseMeasurementId
};

// 파이어베이스 앱 초기화/설정 (이미 초기화되어있으면 기존 설정 사용)
const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// 사용할 기능들은 알아보기 쉽게 이름을 지은뒤 export 해서 필요한곳에 사용하자


export default firebaseApp;
