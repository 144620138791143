const typoNavTexts = {
    en: {
        make: "Make",
        class: "Class",
        explore: "Explore",
        stage: "Stage",
        community: "Community",
        language: "Language",
        login: "Login",
        loout: "Logout",
    },
    ko: {
        make: "만들기",
        class: "교실",
        explore: "탐험",
        stage: "스테이지",
        community: "커뮤니티",
        language: "언어",
        login: "로그인",
        loout: "로그아웃",
    },
};

export default typoNavTexts;
