import React, { useState } from 'react';
import { FiPlusCircle } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import { CgRename } from "react-icons/cg";
import { CiImageOn } from "react-icons/ci";
import { GrUploadOption } from "react-icons/gr";
import Swal from 'sweetalert2';

import RenameModal from './Modal_set/CwsRenameModal';
import AddBackgroundModal from './Modal_set/AddBackgroundModal';
import UploadImageModal from './Modal_set/UploadImageModal';

import BackGroundSetModal from './Modal_set/BackGroundSetModal';

import "./CwsBGSec.css";

import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';





const BgList = [
    { name: 'Book', src: "/assets/background/Magical School/Book.png", type: '#BackGround', dic_index: 1000 },
    { name: 'Bridge', src: "/assets/background/Magical School/Bridge.png", type: '#BackGround', dic_index: 1001 },
    { name: 'BUilding 1', src: "/assets/background/Magical School/Building 1.png", type: '#BackGround', dic_index: 1002 },
    { name: 'BUilding 2', src: "/assets/background/Magical School/Building 2.png", type: '#BackGround', dic_index: 1003 },
    { name: 'BUilding 3', src: "/assets/background/Magical School/Building 3.png", type: '#BackGround', dic_index: 1004 },
    { name: 'BUilding 4', src: "/assets/background/Magical School/Building 4.png", type: '#BackGround', dic_index: 1005 },
    { name: 'BUilding 5', src: "/assets/background/Magical School/Building 5.png", type: '#BackGround', dic_index: 1006 },
    { name: 'BUilding 6', src: "/assets/background/Magical School/Building 6.png", type: '#BackGround', dic_index: 1007 },
    { name: 'BUilding 7', src: "/assets/background/Magical School/Building 7.png", type: '#BackGround', dic_index: 1008 },
    { name: 'BUilding 8', src: "/assets/background/Magical School/Building 8.png", type: '#BackGround', dic_index: 1009 },
    { name: 'BUilding 9', src: "/assets/background/Magical School/Building 9.png", type: '#BackGround', dic_index: 1010 },
    { name: 'BUilding 10', src: "/assets/background/Magical School/Building 10.png", type: '#BackGround', dic_index: 1011 },
    { name: 'BUilding 11', src: "/assets/background/Magical School/Building 11.png", type: '#BackGround', dic_index: 1012 },
    { name: 'BUilding 12', src: "/assets/background/Magical School/Building 12.png", type: '#BackGround', dic_index: 1013 },
    { name: 'BUilding 13', src: "/assets/background/Magical School/Building 13.png", type: '#BackGround', dic_index: 1014 },
    { name: 'BUilding 14', src: "/assets/background/Magical School/Building 14.png", type: '#BackGround', dic_index: 1015 },
    { name: 'BUilding 15', src: "/assets/background/Magical School/Building 15.png", type: '#BackGround', dic_index: 1016 },
    { name: 'BUilding 16', src: "/assets/background/Magical School/Building 16.png", type: '#BackGround', dic_index: 1017 },
    { name: 'BUilding 17', src: "/assets/background/Magical School/Building 17.png", type: '#BackGround', dic_index: 1018 },
    { name: 'BUilding 18', src: "/assets/background/Magical School/Building 18.png", type: '#BackGround', dic_index: 1019 },
    { name: 'BUilding 19', src: "/assets/background/Magical School/Building 19.png", type: '#BackGround', dic_index: 1020 },
    { name: 'BUilding 20', src: "/assets/background/Magical School/Building 20.png", type: '#BackGround', dic_index: 1021 },
    { name: 'BUilding 21', src: "/assets/background/Magical School/Building 21.png", type: '#BackGround', dic_index: 1022 },
    { name: 'BUilding 22', src: "/assets/background/Magical School/Building 22.png", type: '#BackGround', dic_index: 1023 },
    { name: 'BUilding 23', src: "/assets/background/Magical School/Building 23.png", type: '#BackGround', dic_index: 1024 },
    { name: 'BUilding 24', src: "/assets/background/Magical School/Building 24.png", type: '#BackGround', dic_index: 1025 },
    { name: 'BUilding 25', src: "/assets/background/Magical School/Building 25.png", type: '#BackGround', dic_index: 1026 },
    { name: 'BUilding 26', src: "/assets/background/Magical School/Building 26.png", type: '#BackGround', dic_index: 1027 },
];

const TileList = [
    { name: 'Grass', src: "/assets/background/tile_set/g_tile_5_64x64.png", type: '#Tile', dic_index: 1 },
    { name: 'Grain', src: "/assets/background/tile_set/y_tile_5_64x64.png", type: '#Tile', dic_index: 2 },
    { name: 'Rock', src: "/assets/background/tile_set/rock_tile_5_64x64.png", type: '#Tile', dic_index: 3 },
    
]

const isValidPythonIdentifier = (str) => {
    if (str.length === 0) {
        return false;
    }
    const startsWithNumber = /^\d/.test(str);
    const keywords = new Set([
        'False', 'None', 'True', 'and', 'as', 'assert', 'async', 'await', 'break', 'class', 'continue',
        'def', 'del', 'elif', 'else', 'except', 'finally', 'for', 'from', 'global', 'if', 'import',
        'in', 'is', 'lambda', 'nonlocal', 'not', 'or', 'pass', 'raise', 'return', 'try', 'while', 'with', 'yield'
    ]);
    const isKeyword = keywords.has(str);
    const containsInvalidCharacters = !/^[\p{L}\p{N}_]*$/u.test(str);
    const containsSpecialCharacters = /[!@#$%^&*()\-=+\[\]{};':"\\|,.<>\/?]/.test(str);
    const containsWhitespace = /\s/.test(str);
    return !(startsWithNumber || isKeyword || containsInvalidCharacters || containsSpecialCharacters || containsWhitespace);
};

function CwsBGSec({ project, setProject, selectedSceneIndex, selectedObjectName, setSelectedObjectName }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const [imgListState, setImgListState] = useState(BgList);

    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const { language, setLanguage } = useLanguage();
    

    const [selectedIcon, setSelectedIcon] = useState(BgList[0].src);
    const [newBackGround, setNewBackGround] = useState({ name: '', x: 200, y: 200, dir: 0, type: '#BackGround', code: [], isMaster: false });


    const text = texts[language];

    const generateCodeSkeleton = (type,name,width,height,dic_index,code) => {
        if (type ==="#BackGround"){
            return {
                backGround_Setting: `${name}_map = [ # map array\n    [${dic_index}],\n]\ntile_width = ${width}\ntile_height = ${height}\n${name} = MapMaker(num_to_tile_dic, ${name}_map,  tile_width, tile_height) \n\n#num_to_tile_dic: map-info dic <-already setting\n# 2d array to drawing map , tile_width, tile_height\n\n#Find map dic in Board\n#and make it!!\n\nWALL_POSITIONS = ${name}.wall_positions\nTILE_SIZE=tile_width\n\nawait ${name}.backGround_draw() # draw map!`,    
            }
        }else{
        return {
            backGround_Setting: `${name}_map = ${code}\n${name} = MapMaker(num_to_tile_dic, ${name}_map,  tile_width, tile_height) \n\n#num_to_tile_dic: map-info dic <-already setting\n# 2d array to drawing map , tile_width, tile_height\n\n#Find map dic in Board\n#and make it!!\n\nWALL_POSITIONS = ${name}.wall_positions\nTILE_SIZE=tile_width\n\nawait ${name}.backGround_draw() # draw map!`,
        };
        }

    };

    const handleAddBackground = (name,type, isMaster,width,height,dic_index,code) => {
        if (!isValidPythonIdentifier(name)) {
            Swal.fire({
                title: "Name error",
                text: "Invalid background name. Please choose a different name.",
                icon: "error",
            });
            return;
        }

        if (project.objects[name]) {
            Swal.fire({
                title: "Name error",
                text: "Background name already exists. Please choose a different name.",
                icon: "error",
            });
            return;
        }

        const codeSkeleton = generateCodeSkeleton(type,name,width,height,dic_index,code);
        const newBg = {
            name: name,
            isMaster: isMaster,
            x: 0,
            y: 0,
            dir: 0,
            mirror: 0,
            icon: selectedIcon,
            type: type,
            hit_box: 0,
            code: [
                { value: codeSkeleton.backGround_Setting, isOpen: true, edit: true, show: true }, //0 run init code
                { value: '', isOpen: false, edit: false, show: false }, //1 event
                { value: '', isOpen: false, edit: false, show: false }, //2 class func
                { value: '', isOpen: false, edit: false, show: false }, //3 define_class
                { value: '', isOpen: false, edit: false, show: false }, //4 class_define
                { value: '', isOpen: false, edit: false, show: false }, //5 run func
            ],
            scene_index: isMaster ? -1 : selectedSceneIndex
        };

        setProject(prevProject => ({
            ...prevProject,
            objects: { ...prevProject.objects, [newBg.name]: newBg }
        }));
        setModalIsOpen(false);
    };

    const handleDeleteBackground = (name) => {
        setProject(prevProject => {
            const newBackgrounds = { ...prevProject.objects };
            delete newBackgrounds[name];
            return { ...prevProject, objects: newBackgrounds };
        });
        if (selectedObjectName === name) {
            setSelectedObjectName(null);
        }
    };

    const handleBackgroundClick = (name) => {
        setSelectedObjectName(name);
    };

  
    const handleUploadImage = (imageNum, url) => {
        const newBgDicEntry = {
        url:url,
        fileName : `user_${imageNum}`,
        imageNum : imageNum,
        code : `
        "user_${imageNum}": {
            "px_w": 2304, 
            "px_h": 1536, 
            "img-url": "${url}",
            "is_wall": False
        },
            `};
        setProject(prevProject => ({
            ...prevProject,
            userBgDic: [...prevProject.userBgDic, newBgDicEntry]
        }));
    };

    if (!project.objects) {
        return <div>Loading...</div>; // or some other placeholder
    }

    const filteredBackgrounds = Object.values(project.objects).filter(bg => (bg.scene_index === selectedSceneIndex || bg.scene_index === -1) && bg.type.startsWith('#'));

    return (
        <div className="Cws-bg-con">
            <div className="Cws-bg-detail">
                {selectedObjectName !== null && project.objects[selectedObjectName] ? (
                    <>
                        <div className="Cws-bg-detail-controls">
                            <div className="Cws-bg-control-item">
                                <span>{text.bgSec.bgName}</span>
                                <div className="Cws-bg-detail-round-box">{project.objects[selectedObjectName].name}</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='Cws-bg-init-info'>
                        <div className='Cws-bg-init-info-box'>
                            <FiPlusCircle size="30" />
                            <span style={{ fontFamily: "Inter", fontSize: "14px", marginLeft: "10px" }}>를 눌러 Background를 추가하세요!</span>
                        </div>
                        <div className='Cws-bg-init-info-box'>
                            <span style={{ fontFamily: "Inter", fontSize: "12px", marginLeft: "10px" }}>Run을 누르면 compile이 됩니다!</span>
                        </div>
                    </div>
                )}
            </div>

            <div className="Cws-bg-main">
                <div className="Cws-bg-main-scroll-wrap">
                    <div className="Cws-bg-list">
                        {filteredBackgrounds.map((background, index) => {
                            const isActive = selectedObjectName === background.name;
                            const isMaster = background.scene_index === -1;
                            
                            const className = `Cws-bg-box ${isActive ? 'active' : ''} ${isMaster ? 'master' : ''}`;
                            
                            let iconSrc = background.icon;
                            

                            return (
                                <div
                                    key={background.name}
                                    className={className}
                                    onClick={() => handleBackgroundClick(background.name)}
                                >
                                    <div className="Cws-bg-img-box">
                                        <CiImageOn size="40" />
                                    </div>
                                    <div className="Cws-bg-box-name">
                                        <span style={{ fontFamily: "Inter", fontSize: "14px" }}>{background.name}</span>
                                    </div>
                                    <TiDeleteOutline
                                        className="Cws-bg-delete-icon"
                                        size="16"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteBackground(background.name);
                                        }}
                                    />
                                
                                </div>
                            );
                        })}
                        <div className="Cws-bg-add-icon" onClick={() => setModalIsOpen(true)}>
                            <FiPlusCircle size="30" />
                        </div>
                       
                    </div>
                </div>
            </div>
       
          
            
            <BackGroundSetModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                imgList={imgListState}
                setImgList={setImgListState}
                selectedIcon={selectedIcon}
                setSelectedIcon={setSelectedIcon}
                handleAddBackground={handleAddBackground}
                TileList={TileList}
                onUploadImage={handleUploadImage}
            />

        </div>
    );
}

export default CwsBGSec;
