const commandInTexts = {
    en: {
        explain: "Explain",
        param: "Parameter",
        return: "Return",
        example: "Example",
    },
    ko: {
        explain: "설명",
        param: "인수",
        return: "반환값",
        example: "예시",
    },
};

export default commandInTexts;

