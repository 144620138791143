import React from 'react';
import './CwsCMD.css';

import { FaCircle } from "react-icons/fa";

function CwsCMD({ logs }) {
    console.log(logs);
    return (
        <div className="Cws-cmd">
            <div className="Cws-cmd-detail">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaCircle size={"12px"} style={{ marginLeft: "5px", color:"#60D8E0" }}/>
                    <FaCircle size={"12px"} style={{ marginLeft: "5px", color:"#60D8E0" }}/>
                    <FaCircle size={"12px"} style={{ marginLeft: "5px", color:"#fff" }}/>
                    <span style={{fontSize: "14px"}}>TYPO / Terminal</span>
                </div>
            </div>
            <div className="Cws-cmd-main">
                <div className="Cws-cmd-main-scroll-wrap">
                    
                    <div className="Cws-cmd-list">
                        {logs.map((log, index) => (
                            <div key={index} className="Cws-cmd-line">
                            <span >{log}</span>
                            </div>
                        ))}
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default CwsCMD;
