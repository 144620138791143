const objectSetTexts = {
    en: {
        objects: "Objects",
        upload: "Upload",
        masterobject:"Master Object",
        name:"Name",
        add:"Add",
        imgupload:"Upload Image Click to here",
        width:"width",
        height:"height",
    },
    ko: {
        objects: "오브젝트",
        upload: "이미지 업로드",
        masterobject:"마스터 오브젝트",
        name:"이름",
        add:"추가",
        imgupload:"이미지를 업로드하세요!",
        width:"가로",
        height:"세로",
    },
};

export default objectSetTexts;
