import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './CwsSaveProjectModal.css'; // Create a CSS file for styling the modal

const CwsSaveProjectModal = ({ isOpen, onRequestClose, onSave, initialName}) => {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');

    useEffect(() => {
        if (isOpen) {
            setProjectName(initialName || '');
            setProjectDescription('');
        }
    }, [isOpen, initialName]);

    const handleSave = () => {
        
        onSave(projectName, projectDescription);
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Save Project"
            className="Cws-save-project-modal"
            overlayClassName="Cws-save-project-modal-overlay"
        >
            <h2>Save Project</h2>
            <div className="Cws-save-project-form">
                <label>Project Name</label>
                <input
                    type="text"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                />
                <label>Project Description</label>
                <input
                    type="text"
                    value={projectDescription}
                    onChange={(e) => setProjectDescription(e.target.value)}
                />
            </div>

            <button onClick={handleSave} className="add-background-modal-add-button">Add</button>
            <button onClick={onRequestClose} className="add-background-modal-cancel-button">Cancel</button>
        </Modal>
    );
};

export default CwsSaveProjectModal;
