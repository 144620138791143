const navTexts = {
    en: {
        save: "Save",
        load: "Load",
        stage: "Stage",
        language: "Language",
    },
    ko: {
        save: "저장",
        load: "불러오기",
        stage: "스테이지",
        language: "언어",
    },
};

export default navTexts;
