const codeEditorTexts = {
    en: {
        explainToggle: [
            "init code",
            "Event control",
            "Object's function",
            "Class init var",
            "loop code",
        ],
        editorExplainSentance: [
            "Main code that runs when you press Run!",
            "Set the event for the object!",
            "Set the function for the object!",
            "Set the initial variables for the object!",
            "This code runs in a loop until the program ends!",
        ],
    },
    ko: {
        explainToggle: [
            "시작 코드",
            "이벤트 제어",
            "객체의 함수",
            "Class init var",
            "loop code",
        ],
        editorExplainSentance: [
            "Run을 누르면 실행하는 메인 코드에요!",
            "Object의 event를 설정해요!",
            "Object가 가지는 함수를 설정해요!",
            "Object가 가지는 초기 변수를 설정해요!",
            "이곳에 코딩하면 프로그램이 종료될때까지 실행돼요!",
        ],
    },
};

export default codeEditorTexts;
