import React, { useState, useEffect } from 'react';
import "./CwsRightSec.css";
import CwsResultPage from './CwsResultPage';
import CwsObSec from './CwsObSec';
import CwsBGSec from './CwsBGSec';
import CwsCMD from './CwsCMD';
import pre_game_img from '../../img_set/cute-robot-back.gif';
import { LuComponent } from "react-icons/lu";
import { FaTerminal } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import Modal from 'react-modal';
import { BsArrowsFullscreen } from "react-icons/bs";
import Swal from 'sweetalert2';
import { TbMapCode } from "react-icons/tb";
import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';

Modal.setAppElement('#root');

function CwsRightSec({ selectedSceneIndex, Rekey, setRunURL, RunURL, project, setProject, selectedObjectName, setSelectedObjectName, loading, handleCompileRun, logs, setLogs, setSumImg }) {
    const [activeTab, setActiveTab] = useState('board');
    const [isModalOpen, setIsModalOpen] = useState(false);


    const { language, setLanguage } = useLanguage();
    const text = texts[language];

    useEffect(() => {
        const handleAppendMessage = (event) => {
            const { type, message } = event.data;

            if (type === 'python_cmd_log' || type === 'python_cmd_log_error') {
                console.log(message);
                if (type === 'python_cmd_log_error') {
                    Swal.fire({
                        title: "Code Error!",
                        text: "Please check terminal output!",
                        icon: "error",
                        theme: "Bluma"
                    });
                }
                setLogs((prevLogs) => [...prevLogs, message]);
            }
        };

        window.addEventListener('message', handleAppendMessage);

        return () => window.removeEventListener('message', handleAppendMessage);
    }, [setLogs]);

    useEffect(() => {
        if (loading) {
            setLogs([]);
        }
    }, [loading, setLogs]);

    function handleRun() {
        setRunURL('');
    }

    function handleOpenModal() {
        console.log("Opening modal");
        setIsModalOpen(true);
        handleCompileRun();
    }

    function handleCloseModal() {
        console.log("Closing modal");
        setIsModalOpen(false);
    }

    return (
        <div className='Cws-right-sec-con'>
            <div className='Cws-right-sec-head'>
                <div className='Cws-right-sec-head-half-l'>
                    <div
                        className={`Cws-right-sec-tab ${activeTab === 'board' ? 'Cws-right-sec-active' : ''}`}
                        onClick={() => setActiveTab('board')}
                    >
                        <LuComponent size={20} />
                        <span style={{ fontSize: "14px", marginLeft: "10px" }}>{text.rightSec.object}</span>
                    </div>

                    <div
                        className={`Cws-right-sec-tab ${activeTab === 'back' ? 'Cws-right-sec-active' : ''}`}
                        onClick={() => setActiveTab('back')}
                    >
                        <TbMapCode size={20} />
                        <span style={{ fontSize: "14px", marginLeft: "10px" }}>{text.rightSec.backGround}</span>
                    </div>

                    <div
                        className={`Cws-right-sec-tab ${activeTab === 'project' ? 'Cws-right-sec-active' : ''}`}
                        onClick={() => setActiveTab('project')}
                    >
                        <FaTerminal size={20} />
                        <span style={{ fontSize: "14px", marginLeft: "10px" }}>{text.rightSec.terminal}</span>
                    </div>
                </div>
                <div className='Cws-right-sec-head-half-r'>
                    <div className="Cws-fullscreen-open-icon" onClick={handleOpenModal}>
                        <BsArrowsFullscreen size={20} />
                    </div>
                </div>
            </div>
            <div className='Cws-right-sec-body'>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        border: '2px solid #E0E0E0',
                    }}
                >
                    {loading ? (
                        <div className="editor-spinner-box"></div>
                    ) : RunURL === '' ? (
                        <img
                            src={pre_game_img}
                            alt="Cute Robot"
                            style={{
                                width: '200px',
                                height: '200px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <CwsResultPage key={Rekey}
                            project={project}
                            setProject={setProject}
                            RunURL={RunURL}
                            setSumImg = {setSumImg}      
                        />
                    )}
                </div>
                <div className='Cws-right-sec-obcon'>
                    {activeTab === 'board' ? (
                        <CwsObSec selectedSceneIndex={selectedSceneIndex}
                            project={project}
                            setProject={setProject}
                            selectedObjectName={selectedObjectName}
                            setSelectedObjectName={setSelectedObjectName}

                        />
                    ) : activeTab === 'back' ? (
                        <CwsBGSec selectedSceneIndex={selectedSceneIndex}
                            project={project}
                            setProject={setProject}
                            selectedObjectName={selectedObjectName}
                            setSelectedObjectName={setSelectedObjectName}

                        />
                    ) : (
                        <CwsCMD key={Rekey} logs={logs} />
                    )}
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Fullscreen View"
                className="Cws-fullscreen-modal"
                overlayClassName="Cws-fullscreen-overlay"
            >
                <div className="Cws-fullscreen-close-icon" onClick={handleCloseModal}>
                    <RiCloseCircleFill size="25" />
                </div>
                <CwsResultPage key={Rekey}
                    project={project}
                    setProject={setProject}
                    RunURL={RunURL}
                    setSumImg = {setSumImg}
                />
            </Modal>
        </div>
    );
}

export default CwsRightSec;
