import React, { useState, useEffect } from 'react';
import "./CwsCodeEditor.css";
import { LuCode2 } from "react-icons/lu";

import './CwsLeftSec.css';
import { FaBookOpen } from "react-icons/fa";
import { RiGameFill } from "react-icons/ri";
import CwsNotionView from './CwsNotionView';
import CwsToggleEditor from './CwsToggleEditor';
import { AiOutlineGlobal } from "react-icons/ai";
import { IoLeafOutline } from "react-icons/io5";
import { RiQuillPenLine } from "react-icons/ri";
import CwsCommandEx from './CwsCommandEx';
import Swal from 'sweetalert2';
import { PiCodeBlock } from "react-icons/pi";
import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';
import { TbWorldCode } from "react-icons/tb";

function CwsCodeEditor({ selectedSceneIndex, setSelectedSceneIndex, RunURL, setRunURL, project, setProject, selectedObjectName, setLoading, handleCompileRun, handleRunReset }) {
    const [textAreas, setTextAreas] = useState([]);
    const [activeTab, setActiveTab] = useState('local');
    const [newSceneName, setNewSceneName] = useState('');
    const { language } = useLanguage();
    const text = texts[language];

    const explainToggle = text.codeEditor.explainToggle;
    const editorExplainSentance = text.codeEditor.editorExplainSentance;

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === "changeScene") {
                const { index } = event.data.message;
                if (index !== selectedSceneIndex) {
                    setSelectedSceneIndex(index);
                }
            }
        };
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [selectedSceneIndex]);

    const handleAddScene = () => {
        if (newSceneName.trim() === '') {
            Swal.fire({
                title: "Name Needed!",
                text: "Scene name cannot be empty.",
                icon: "warning",
            });
            return;
        }
        const newSceneIndex = project.scenes.length;
        const newScenes = [...project.scenes, { name: newSceneName, index: newSceneIndex }];

        setProject(prevProject => ({ ...prevProject, scenes: newScenes }));
        setNewSceneName('');
        setSelectedSceneIndex(newSceneIndex);
    };

    const handleEditorChange = (value) => {
        const updatedProject = { ...project };
        updatedProject.masterCode[0].value = value;
        setProject(updatedProject);
    };

    const renderContent = () => {
        if (activeTab === 'scene') {
            return (
                <>
                    <div className="Cws-fixed-editor-container">
                        <div className='Cws-fixed-editor-name-con'>
                            <AiOutlineGlobal size="25" />
                            <span className="language-dependent-text">{text.leftSec.masterCode}</span>
                        </div>
                        <div className="Cws-fixed-editor">
                            <CwsToggleEditor
                                explainSentance="Master Code"
                                initialValue={project.masterCode[0].value}
                                onChange={handleEditorChange}
                                isOpen={true}
                                handleToggle={() => { }} // If you need a toggle function, add it here
                            />
                        </div>
                    </div>
                    <div className="Cws-scene-list-container-wrap">
                        <div className="scene-list-container">
                            <div className="scene-list">
                                {project.scenes.map((scene, index) => (
                                    <div
                                        key={index}
                                        className={`scene-item ${selectedSceneIndex === index ? 'active' : ''}`}
                                        onClick={() => {
                                            setSelectedSceneIndex(index);
                                            console.log(index); // This will log the clicked index
                                        }}
                                    >
                                        <IoLeafOutline size="20" />
                                        <span className="language-dependent-text">{scene.index}: {scene.name} </span>
                                    </div>
                                ))}
                            </div>
                            <div className="add-scene-container">
                                <input
                                    type="text"
                                    value={newSceneName}
                                    onChange={(e) => setNewSceneName(e.target.value)}
                                    placeholder="New scene name"
                                />
                                <button onClick={handleAddScene}>{text.leftSec.addScene}</button>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div className='Cws-code-editor-backstage'>
                {textAreas.map((textArea, index) => (
                    <div key={index}>
                        <div className="Cws-code-explain-toggle">{explainToggle[index]}</div>
                        <CwsToggleEditor
                            explainSentance={editorExplainSentance[index]}
                            initialValue={textArea.value}
                            onChange={(value) => handleChange(index, value)}
                            isOpen={textArea.isOpen}
                            handleToggle={() => handleToggle(index)}
                        />
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (project && project.objects && selectedObjectName && project.objects[selectedObjectName]) {
            const selectedCode = project.objects[selectedObjectName].code.filter(codeObj => codeObj.show);
            setTextAreas(selectedCode);
        } else {
            setTextAreas([]);
        }
    }, [selectedObjectName, project]);

    const handleToggle = (index) => {
        const newTextAreas = textAreas.map((textArea, i) =>
            i === index ? { ...textArea, isOpen: !textArea.isOpen } : textArea
        );
        setTextAreas(newTextAreas);
        updateObjectTextAreas(newTextAreas);
    };

    const handleChange = (index, value) => {
        const newTextAreas = textAreas.map((textArea, i) =>
            i === index ? { ...textArea, value: value } : textArea
        );
        setTextAreas(newTextAreas);
        updateObjectTextAreas(newTextAreas);
    };

    const updateObjectTextAreas = (newTextAreas) => {
        if (project && project.objects && selectedObjectName) {
            const updatedObjects = { ...project.objects };
            const newCode = updatedObjects[selectedObjectName].code.map((codeObj, j) => {
                const updatedTextArea = newTextAreas.find((textArea, k) => k === j);
                return updatedTextArea ? { ...codeObj, value: updatedTextArea.value, isOpen: updatedTextArea.isOpen } : codeObj;
            });
            updatedObjects[selectedObjectName].code = newCode;
            setProject({ ...project, objects: updatedObjects });
        }
    };

    return (
        <div className='Cws-code-editor-con'>
            <div className='Cws-code-editor-head'>
                <div className='Cws-code-editor-head-half-l'>
                    <div
                        className={`Cws-code-editor-tab ${activeTab === 'local' ? 'Cws-code-editor-active' : ''}`}
                        onClick={() => setActiveTab('local')}
                    >
                        <PiCodeBlock size={20} />
                        <span className="language-dependent-text" style={{ marginLeft: "10px" }}>{text.editor.local}</span>
                    </div>
                    <div
                        className={`Cws-code-editor-tab ${activeTab === 'scene' ? 'Cws-code-editor-active' : ''}`}
                        onClick={() => setActiveTab('scene')}
                    >
                        <TbWorldCode size={20} />

                        <span className="language-dependent-text" style={{ marginLeft: "10px" }}>{text.editor.global}</span>
                    </div>
                </div>
                <div className='Cws-code-editor-head-half-r'>
                    <button className="Cws-code-editor-custom-button" onClick={handleCompileRun}>Run</button>
                    <button className="Cws-code-editor-custom-button" onClick={handleRunReset}>Reset</button>
                </div>
            </div>
            <div className='Cws-code-editor-body'>
                <div className="Cws-code-editor-scroll-wrap">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default CwsCodeEditor;
