const bgSetTexts = {
    en: {
        bg: "BackGround",
        tile:"Tile",
        upload: "Upload",
        masterbg:"Master BackGround",
        bgname:"Background Name",
        add:"Add",
        imgupload:"Upload Image Click to here",
        width:"width",
        height:"height",
    },
    ko: {
        bg: "배경",
        tile:"타일",
        upload: "이미지 업로드",
        masterbg:"마스터 배경",
        bgname:"배경 이름",
        add:"추가",
        imgupload:"이미지를 업로드하세요!",
        width:"가로",
        height:"세로",
    },
};

export default bgSetTexts;
