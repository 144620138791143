const commandsTexts = {
    en: [
        
            {
                category: "move",
                name: "set_x",
                parameters: [100],
                description: "Sets the object's x-coordinate to A.",
                parameters_exp: "A: Object's x-coordinate (int or float)",
                return_exp: "None",
                exampleCode: "hero.set_x(100)",
                path: "/assets/command_ex_gif/set_x.gif",
            },
            {
                category: "move",
                name: "set_y",
                parameters: [100],
                description: "Sets the object's y-coordinate to A.",
                parameters_exp: "A: Object's y-coordinate (int or float)",
                return_exp: "None",
                exampleCode: "hero.set_y(100)",
                path: "/assets/command_ex_gif/set_y.gif",
            },
            {
                category: "move",
                name: "set_xy",
                parameters: [100, 100],
                description: "Sets the object's x-coordinate to A and y-coordinate to B.",
                parameters_exp: "A: Object's x-coordinate (int or float)\nB: Object's y-coordinate (int or float)",
                return_exp: "None",
                exampleCode: "hero.set_xy(100, 100)",
                path: "/assets/command_ex_gif/set_xy.gif",
            },
            {
                category: "move",
                name: "set_dir",
                parameters: [45],
                description: "Sets the object's angle to A(°).",
                parameters_exp: "A: Direction the object is facing (°) (int or float)",
                return_exp: "None",
                exampleCode: "hero.set_dir(45)",
                path: "/assets/command_ex_gif/set_dir.gif",
            },
            {
                category: "move",
                name: "ahead",
                parameters: [],
                description: "Makes the object face A.",
                parameters_exp: "A: Object the object is facing (object)",
                return_exp: "None",
                exampleCode: "hero.ahead(goblin)",
                path: "/assets/command_ex_gif/ahead.gif",
            },
            {
                category: "move",
                name: "move_x",
                parameters: [50],
                description: "Moves the object A units along the x-axis.",
                parameters_exp: "A: Change in x-coordinate (int or float)",
                return_exp: "None",
                exampleCode: "hero.move_x(50)",
                path: "/assets/command_ex_gif/move_x.gif",
            },
            {
                category: "move",
                name: "move_y",
                parameters: [50],
                description: "Moves the object A units along the y-axis.",
                parameters_exp: "A: Change in y-coordinate (int or float)",
                return_exp: "None",
                exampleCode: "hero.move_y(50)",
                path: "/assets/command_ex_gif/move_y.gif",
            },
            {
                category: "move",
                name: "move_step",
                parameters: [50],
                description: "Moves the object A units in the direction it is facing and rests for 1 second.",
                parameters_exp: "A: Distance to move (int or float)",
                return_exp: "None",
                exampleCode: "hero.move_step(50)",
                path: "/assets/command_ex_gif/move_step.gif",
            },
            {
                category: "move",
                name: "move_dir",
                parameters: [50],
                description: "Moves the object A units in the direction it is facing.",
                parameters_exp: "A: Distance to move (int or float)",
                return_exp: "None",
                exampleCode: "hero.move_dir(50)",
                path: "/assets/command_ex_gif/move_dir.gif",
            },
            {
                category: "move",
                name: "turn_left",
                parameters: [45],
                description: "Rotates the object A(°) counterclockwise.",
                parameters_exp: "A: Angle to rotate (int or float)",
                return_exp: "None",
                exampleCode: "hero.turn_left(60)",
                path: "/assets/command_ex_gif/turn_left.gif",
            },
            {
                category: "move",
                name: "turn_right",
                parameters: [45],
                description: "Rotates the object A(°) clockwise.",
                parameters_exp: "A: Angle to rotate (int or float)",
                return_exp: "None",
                exampleCode: "hero.turn_right(60)",
                path: "/assets/command_ex_gif/turn_right.gif",
            },
            {
                category: "feature",
                name: "set_size",
                parameters: [2],
                description: "Changes the object's size by A.",
                parameters_exp: "A: Size change value (int or float)",
                return_exp: "None",
                exampleCode: "hero.set_size(2)",
                path: "/assets/command_ex_gif/set_size.gif",
            },
            {
                category: "feature",
                name: "set_mirror",
                parameters: [1],
                description: "Mirrors the object horizontally.",
                parameters_exp: "A: 0 or 1 (0 no mirror, 1 mirror)",
                return_exp: "None",
                exampleCode: "hero.set_mirror(1)",
                path: "/assets/command_ex_gif/set_mirror.gif",
            },
            {
                category: "feature",
                name: "say",
                parameters: ["Hello!"],
                description: "Makes the object say A.",
                parameters_exp: "A: Content of the speech bubble (same format as print)",
                return_exp: "None",
                exampleCode: "hero.say('Hello!')",
                path: "/assets/command_ex_gif/say.gif",
            },
            {
                category: "feature",
                name: "hide",
                parameters: [],
                description: "Makes the object invisible.",
                parameters_exp: "None",
                return_exp: "None",
                exampleCode: "hero.hide()",
                path: "/assets/command_ex_gif/hide.gif",
            },
            {
                category: "feature",
                name: "show",
                parameters: [],
                description: "Makes the object visible.",
                parameters_exp: "None",
                return_exp: "None",
                exampleCode: "hero.show()",
                path: "/assets/command_ex_gif/show.gif",
            },
            {
                category: "feature",
                name: "motion",
                parameters: ["attack"],
                description: "Changes the object's motion to A.",
                parameters_exp: "A: Motion name (str)",
                return_exp: "None",
                exampleCode: "hero.motion('attack')",
                path: "/assets/command_ex_gif/motion.gif",
            },
            {
                category: "feature",
                name: "motion",
                parameters: ["attack", 2],
                description: "Changes the object's motion to A for B times.",
                parameters_exp: "A: Motion name (str)\nB: Number of motions (int)",
                return_exp: "None",
                exampleCode: "hero.motion('attack', 2)",
                path: "/assets/command_ex_gif/motion(A,B).gif",
            },
            {
                category: "return",
                name: "get_x",
                parameters: [],
                description: "Returns the object's x-coordinate.",
                parameters_exp: "None",
                return_exp: "x-coordinate (int or float)",
                exampleCode: "hero.say(hero.get_x())",
                path: "/assets/command_ex_gif/get_x.gif",
            },
            {
                category: "return",
                name: "get_y",
                parameters: [],
                description: "Returns the object's y-coordinate.",
                parameters_exp: "None",
                return_exp: "y-coordinate (int or float)",
                exampleCode: "hero.say(hero.get_y())",
                path: "/assets/command_ex_gif/get_y.gif",
            },
            {
                category: "return",
                name: "get_text",
                parameters: [],
                description: "Returns the text in the speech bubble.",
                parameters_exp: "None",
                return_exp: "Content of the speech bubble (str)",
                exampleCode: "hero.say('Hello!')\nhero.sleep(2)\nhero.say(hero.get_text(), 'Nice to meet you!')",
                path: "/assets/command_ex_gif/get_text.gif",
            },
            {
                category: "return",
                name: "get_motion",
                parameters: [],
                description: "Returns the current motion name.",
                parameters_exp: "None",
                return_exp: "Motion name (str)",
                exampleCode: "hero.say(hero.get_motion())",
                path: "/assets/command_ex_gif/get_motion.gif",
            },
            {
                category: "return",
                name: "get_name",
                parameters: [],
                description: "Returns the object's name.",
                parameters_exp: "None",
                return_exp: "Object name (str)",
                exampleCode: "hero.say(hero.get_name())",
                path: "/assets/command_ex_gif/get_name.gif",
            },
            {
                category: "return",
                name: "get_dir",
                parameters: [],
                description: "Returns the current rotation angle.",
                parameters_exp: "None",
                return_exp: "Direction (°) (int or float)",
                exampleCode: "hero.turn_right(90)\nhero.sleep(3)\nhero.set_dir(hero.get_dir() + 90)",
                path: "/assets/command_ex_gif/get_dir.gif",
            },
            {
                category: "check",
                name: "check_collision",
                parameters: [],
                description: "Returns whether it is touching A.",
                parameters_exp: "A: Object to check (object)",
                return_exp: "True or False (bool)",
                exampleCode: "while True:\n    hero.sleep(0.01)\n    hero.move_step(10)\n    if hero.check(goblin):\n        hero.say('Oh no!')",
                path: "/assets/command_ex_gif/check_collision.gif",
            },
            {
                category: "clone",
                name: "clone",
                parameters: [],
                description: "Clones the object.",
                parameters_exp: "None",
                return_exp: "Cloned object (object)",
                exampleCode: "hero2 = hero.clone()\nclones.append(hero2)\nhero2.set_x(300)",
                path: "/assets/command_ex_gif/clone().gif",
            },
            {
                category: "clone",
                name: "clones.append(A)",
                parameters: [],
                description: "Include A the replicated object in the execution result.",
                parameters_exp: "A : clone object name",
                return_exp: "None",
                exampleCode: "hero2 = hero.clone()\nclones.append(hero2)\nhero2.set_x(300)",
                path: "/assets/command_ex_gif/clones.append.gif",
            },
            {
                category: "flow",
                name: "stop()",
                parameters: [],
                description: "Stop code execution.",
                parameters_exp: "None",
                return_exp: "None",
                exampleCode: "체력 = 50\nwhile True :\n    hero.say(체력)\n    hero.sleep(1)\n    if 체력 ≤ 0  :\n        stop()\n    체력 = 체력 - 10",
                path: "/assets/command_ex_gif/stop.gif",
            },
            {
                category: "flow",
                name: "sleep",
                parameters: [1],
                description: "Wait A seconds before executing the next code.",
                parameters_exp: "A: sleep sec(int or float)",
                return_exp: "None",
                exampleCode: "hero.say(”hello”)\nhero.sleep(3)\nhero.say(”bye~”)",
                path: "/assets/command_ex_gif/sleep.gif",
            }
            // Add more commands here
        
    ],
    ko: [
        {
            category:"move",
            name: "set_x",
            parameters: [100],
            description: "오브젝트의 x좌표를 A로 설정합니다.",
            parameters_exp:"A : 오브젝트의 x좌표 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.set_x(100)",
            path:"/assets/command_ex_gif/set_x.gif",
        },
        {
            category:"move",
            name: "set_y",
            parameters: [100],
            description: "오브젝트의 y좌표를 A로 설정합니다.",
            parameters_exp:"B : 오브젝트의 x좌표 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.set_y(100)",
            path:"/assets/command_ex_gif/set_y.gif",
        },
        {
            category:"move",
            name: "set_xy",
            parameters: [100,100],
            description: "오브젝트의 x좌표를 A, y좌표를 B로 설정합니다.",
            parameters_exp:"A : 오브젝트의 x좌표 (int or float)\nB : 오브젝트의 y좌표 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.set_xy(100,100)",
            path:"/assets/command_ex_gif/set_xy.gif",
        },
        {
            category:"move",
            name: "set_dir",
            parameters: [45],
            description: "오브젝트의 각도를 A( °)로 설정합니다.",
            parameters_exp:"A : 오브젝트가 바라보는 방향( °) (int or float)",
            return_exp:"없음",
            exampleCode: "hero.set_dir(45)",
            path:"/assets/command_ex_gif/set_dir.gif",
        },
        {
            category:"move",
            name: "ahead",
            parameters: [],
            description: "오브젝트가 A를 바라보게 합니다.",
            parameters_exp:"A : 오브젝트가 바라보는 오브젝트 (object)",
            return_exp:"없음",
            exampleCode: "hero.ahead(goblin)",
            path:"/assets/command_ex_gif/ahead.gif",
        },
        {
            category:"move",
            name: "move_x",
            parameters: [50],
            description: "오브젝트가 x축 방량으로 A만큼 움직입니다.",
            parameters_exp:"A : x좌표의 변화 값 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.move_x(50)",
            path:"/assets/command_ex_gif/move_x.gif",
        },
        {
            category:"move",
            name: "move_y",
            parameters: [50],
            description: "오브젝트가 y축 방량으로 A만큼 움직입니다.",
            parameters_exp:"A : y좌표의 변화 값 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.move_y(50)",
            path:"/assets/command_ex_gif/move_y.gif",
        },
        {
            category:"move",
            name: "move_step",
            parameters: [50],
            description: "오브젝트가 바라보는 방향으로 A만큼 움직이고 1초간 쉽니다.",
            parameters_exp:"A : 이동거리 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.move_step(50)",
            path:"/assets/command_ex_gif/move_step.gif",
        },
        {
            category:"move",
            name: "move_dir",
            parameters: [50],
            description: "오브젝트가 바라보는 방향으로 A만큼 움직입니다.",
            parameters_exp:"A : 이동거리 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.move_dir(50)",
            path:"/assets/command_ex_gif/move_dir.gif",
        },
        {
            category:"move",
            name: "turn_left",
            parameters: [45],
            description: "오브젝트가 반시계 방향으로 A( °)만큼 회전합니다.",
            parameters_exp:"A : 회전할 각도 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.turn_left(60)",
            path:"/assets/command_ex_gif/turn_left.gif",
        },
        {
            category:"move",
            name: "turn_right",
            parameters: [45],
            description: "오브젝트가 시계 방향으로 A( °)만큼 회전합니다.",
            parameters_exp:"A : 회전할 각도 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.turn_right(60)",
            path:"/assets/command_ex_gif/turn_right.gif",
        },
        {
            category:"feature",
            name: "set_size",
            parameters: [2],
            description: "오브젝트 크기가 A만큼 변합니다.",
            parameters_exp:"A : 크기 변화 값 (int or float)",
            return_exp:"없음",
            exampleCode: "hero.set_size(2)",
            path:"/assets/command_ex_gif/set_size.gif",
        },
        {
            category:"feature",
            name: "set_mirror",
            parameters: [1],
            description: "오브젝트를 좌우대칭 시킵니다.",
            parameters_exp:"A : 0 or 1 (0 반전 x, 1 반전 o)",
            return_exp:"없음",
            exampleCode: "hero.set_mirror(1)",
            path:"/assets/command_ex_gif/set_mirror.gif",
        },
        {
            category:"feature",
            name: "say",
            parameters: ["안녕!"],
            description: "오브젝트가 A를 말하게 합니다.",
            parameters_exp:"A : 말풍선 안 내용 (print와 동일한 서식)",
            return_exp:"없음",
            exampleCode: "hero.say(”안녕!”)",
            path:"/assets/command_ex_gif/say.gif",
        },
        {
            category:"feature",
            name: "hide",
            parameters: [],
            description: "오브젝트가 안 보이게 합니다.",
            parameters_exp:"없음",
            return_exp:"없음",
            exampleCode: "hero.hide()",
            path:"/assets/command_ex_gif/hide.gif",
        },
        {
            category:"feature",
            name: "show",
            parameters: [],
            description: "오브젝트를 보이게 합니다.",
            parameters_exp:"없음",
            return_exp:"없음",
            exampleCode: "hero.show()",
            path:"/assets/command_ex_gif/show.gif",
        },
        {
            category:"feature",
            name: "motion",
            parameters: ["attack"],
            description: "오브젝트의 모션을 A로 바꿉니다.",
            parameters_exp:"A : 모션 이름 (str)",
            return_exp:"없음",
            exampleCode: "hero.motion(”attack”)",
            path:"/assets/command_ex_gif/motion.gif",
        },
        {
            category:"feature",
            name: "motion",
            parameters: ["attack",2],
            description: "오브젝트의 모션을 A로 B번 바꿉니다.",
            parameters_exp:"A : 모션 이름 (str)\nB: 모션 횟수 (int)",
            return_exp:"없음",
            exampleCode: "hero.motion(”attack”,2)",
            path:"/assets/command_ex_gif/motion(A,B).gif",
        },
        {
            category:"return",
            name: "get_x",
            parameters: [],
            description: "오브젝트의 x좌표를 반환합니다.",
            parameters_exp:"없음",
            return_exp:"x좌표 (int or float)",
            exampleCode: "hero.say(hero.get_x())",
            path:"/assets/command_ex_gif/get_x.gif",
        },
        {
            category:"return",
            name: "get_y",
            parameters: [],
            description: "오브젝트의 y좌표를 반환합니다.",
            parameters_exp:"없음",
            return_exp:"y좌표 (int or float)",
            exampleCode: "hero.say(hero.get_y())",
            path:"/assets/command_ex_gif/get_y.gif",
            
        },
        {
            category:"return",
            name: "get_text",
            parameters: [],
            description: "말풍선 안의 말을 반환합니다.",
            parameters_exp:"없음",
            return_exp:"말풍선 안의 내용 (str)",
            exampleCode: "hero.say(”안녕!”)\nhero.sleep(2)\nhero.say(hero.get_text(),”반가워!”)",
            path:"/assets/command_ex_gif/get_text.gif",
        },
        {
            category:"return",
            name: "get_motion",
            parameters: [],
            description: "현재 모션 이름을 반환합니다.",
            parameters_exp:"없음",
            return_exp:"모션 이름 (str)",
            exampleCode: "hero.say(hero.get_motion())",
            path:"/assets/command_ex_gif/get_motion.gif",
        },
        {
            category:"return",
            name: "get_name",
            parameters: [],
            description: "오브젝트 이름을 반환합니다.",
            parameters_exp:"없음",
            return_exp:"오브젝트 이름 (str)",
            exampleCode: "hero.say(hero.get_name())",
            path:"/assets/command_ex_gif/get_name.gif",
        },
        {
            category:"return",
            name: "get_dir",
            parameters: [],
            description: "현재 회전 각도를 반환합니다.",
            parameters_exp:"없음",
            return_exp:"방향( °) (int or float)",
            exampleCode: "hero.turn_right(90)\nhero.sleep(3)\nhero.set_dir(hero.get_dir() +90)",
            path:"/assets/command_ex_gif/get_dir.gif",
        },
        {
            category:"check",
            name: "check_collision",
            parameters: [],
            description: "A와 닿았는 지 여부를 반환합니다.",
            parameters_exp:"A : 확인 대상 (object)",
            return_exp:"참 or 거짓 (bool)",
            exampleCode: "while True :\n    hero.sleep(0.01)\n    hero.move_xtep(10)\n    if hero.check(goblin) :\n        hero.say(”으악!”)",
            path:"/assets/command_ex_gif/check_collsion.gif",
        },
        {
            category:"clone",
            name: "clone",
            parameters: [],
            description: "오브젝트를 복제합니다.",
            parameters_exp:"없음",
            return_exp:"복제된 오브젝트 (object)",
            exampleCode: "hero2 = hero.clone()\nclones.append(hero2)\nhero2.set_x(300)",
            path:"/assets/command_ex_gif/clone().gif",
        },
        {
            category: "clone",
            name: "clones.append(A)",
            parameters: [],
            description: "복제된 오브젝트를 실행 결과에 포함시킵니다.",
            parameters_exp: "A : 클론 이름",
            return_exp: "None",
            exampleCode: "hero2 = hero.clone()\nclones.append(hero2)\nhero2.set_x(300)",
            path: "/assets/command_ex_gif/clones.append.gif",
        },
        {
            category: "flow",
            name: "stop()",
            parameters: [],
            description: "코드 실행을 멈춥니다.",
            parameters_exp: "None",
            return_exp: "None",
            exampleCode: "체력 = 50\nwhile True :\n    hero.say(체력)\n    hero.sleep(1)\n    if 체력 ≤ 0  :\n        stop()\n    체력 = 체력 - 10",
            path: "/assets/command_ex_gif/stop.gif",
        },
        {
            category: "flow",
            name: "sleep",
            parameters: [1],
            description: "다음 코드를 실행하기 전, A초 만큼 기다립니다.",
            parameters_exp: "A : 기다리는 시간(초)",
            return_exp: "None",
            exampleCode: "hero.say(”안녕”)\nhero.sleep(3)\nhero.say(”잘가”)",
            path: "/assets/command_ex_gif/sleep.gif",
        }
    ],
};

export default commandsTexts;
