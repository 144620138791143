import React, { useState, useEffect, useRef } from 'react';
import "./TypoMainBanner.css";

function TypoMainBanner() {
    const canvasRef = useRef(null);
    const imgRef = useRef(null);
    const [position, setPosition] = useState({ top: 50, left: 50 });
    const [direction, setDirection] = useState({ x: 1, y: 1 });

    // Function to move the image around
    const moveImage = () => {
        const container = imgRef.current.parentElement;
        const img = imgRef.current;

        let newLeft = position.left + direction.x * 2; // Adjust speed here
        let newTop = position.top + direction.y * 2;

        if (newLeft <= 0 || newLeft + img.clientWidth >= container.clientWidth) {
            setDirection((prev) => ({ ...prev, x: -prev.x }));
        }
        if (newTop <= 0 || newTop + img.clientHeight >= container.clientHeight) {
            setDirection((prev) => ({ ...prev, y: -prev.y }));
        }

        setPosition({
            left: Math.min(Math.max(newLeft, 0), container.clientWidth - img.clientWidth),
            top: Math.min(Math.max(newTop, 0), container.clientHeight - img.clientHeight),
        });
    };

    useEffect(() => {
        const interval = setInterval(moveImage, 10); // Move image every 10ms
        return () => clearInterval(interval);
    }, [position, direction]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const text = "TYPO"; // Text to display
        const fontSize = 100;
        const pixelSize = 5; // Size of each pixel

        canvas.width = 500;
        canvas.height = 150;

        ctx.fillStyle = '#65eaff';
        ctx.font = `${fontSize}px Arial`;
        ctx.fillText(text, 50, 100); // Draw text on canvas

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        let index = 0;
        const pixels = [];

        for (let y = 0; y < canvas.height; y += pixelSize) {
            for (let x = 0; x < canvas.width; x += pixelSize) {
                const i = (y * canvas.width + x) * 4;
                if (imageData.data[i + 3] > 128) { // Only save opaque pixels
                    pixels.push({ x, y });
                }
            }
        }

        const drawPixel = () => {
            if (index >= pixels.length) {
                clearInterval(interval);
                setTimeout(() => {
                    index = 0;
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    interval = setInterval(drawPixel, 30); // Redraw pixels every 30ms
                }, 1000);
            } else {
                const { x, y } = pixels[index];
                ctx.fillRect(x, y, pixelSize, pixelSize);
                index++;
            }
        };

        let interval = setInterval(drawPixel, 30); // Draw pixel every 30ms

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <div className='Typo-banner-con'>
            <canvas ref={canvasRef}></canvas>
            <img 
                src="/typo_ghost_logo.png" 
                alt="Ghost Logo" 
                ref={imgRef} 
                style={{ position: 'absolute', top: `${position.top}px`, left: `${position.left}px` }} 
            />
        </div>
    );
}

export default TypoMainBanner;
