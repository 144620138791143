import React, { useState } from 'react';
import Modal from 'react-modal';
import "./BackGroundSetModal.css";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoGameController } from "react-icons/io5";
import { TbTower } from "react-icons/tb";
import { FaUpload } from "react-icons/fa6";
import { AiOutlineBuild } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import CwsToggleEditor from '../CwsToggleEditor';
import Swal from 'sweetalert2';
import { useLanguage } from '../../../LanguageContext';
import texts from '../../../texts';

const BackGroundSetModal = ({ isOpen, onRequestClose, imgList, setImgList, TileList, selectedIcon, setSelectedIcon, handleAddBackground, onUploadImage }) => {
    const [activeTab, setActiveTab] = useState('All'); // State to handle active tab
    const [width, setWidth] = useState(500); // State to handle width input
    const [height, setHeight] = useState(500); // State to handle height input
    const [tileWidth, setTileWidth] = useState(32); // State to handle width input
    const [tileHeight, setTileHeight] = useState(32); // State to handle height input
    const [name, setName] = useState('');
    const [isMaster, setIsMaster] = useState(false);
    const [code, setCode] = useState('');
    const [dic_index, setDic_index] = useState(1000);
    const [type, setType] = useState("#BackGround");
    const [image, setImage] = useState(null);
    const [imageNum, setImageNum] = useState(10000);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [tileRow, setTileRow] = useState(3);
    const [tileCol, setTileCol] = useState(3);

    const { language } = useLanguage();
    const text = texts[language];

    const handleFileUpload = (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                const url = URL.createObjectURL(file);
                const newIcon = { name: `user_${file.name}`, src: url, type: '#BackGround', dic_index: imageNum };
                const reader = new FileReader();
                
                reader.onload = () => {
                    setPreviewImageUrl(reader.result);
                };
                reader.readAsDataURL(file);
                setImgList(prevImgList => [...prevImgList, newIcon]);
                setImage({ file, url });
                setSelectedIcon(url);
            }

            Swal.fire({
                title: "Load Success",
                text: `check Object tab!`,
                icon: "success"
            });
        } catch (error) {
            Swal.fire({
                title: "Load error",
                text: "Failed to image upload.",
                icon: "error"
            });
        }
    };

    const handleAddImage = () => {
        if (!image) {
            Swal.fire({
                title: "Error",
                text: "Please upload an image.",
                icon: "error",
            });
            return;
        }

        if (parseInt(imageNum, 10) < 10000) {
            Swal.fire({
                title: "Error",
                text: "Image number must be 10000 or greater.",
                icon: "error",
            });
            return;
        }

        onUploadImage(imageNum, image.url);
        setImage(null);
        setImageNum('');
        onRequestClose();
        setActiveTab("All");
        setType("#BackGround");
        Swal.fire({
            title: "Add Success",
            text: `Use image in bg code!`,
            icon: "success"
        });
    };

    const handleTileMapMaker = () => {
        let mapArray = [];
        // Row와 Col에 따라 map array 생성
        for (let i = 0; i < tileRow; i++) {
            let rowArray = [];
            for (let j = 0; j < tileCol; j++) {
                rowArray.push(1); // 기본 값으로 1을 추가
            }
            mapArray.push(rowArray);
        }
        // mapArray를 문자열로 변환
        const mapArrayString = mapArray.map(row => `[${row.join(',')}]`).join(',\n    ');

        // 최종 코드 생성
        const finalCode = `[ # map array\n    ${mapArrayString}\n]\ntile_width = ${tileWidth}\ntile_height = ${tileHeight}`;
        setWidth(tileWidth * tileRow);
        setHeight(tileHeight * tileCol);
        // 코드 상태 업데이트
        setCode(finalCode);
    };
    const handleEditorChange = (value) => {
        setCode(value);
    };


    const closeModal = () => {
        onRequestClose();
        setActiveTab("All");
        setType("#BackGround");
    };

    const addNewBackGround = () => {
        if (!name.trim()) {
            alert("Please enter a name for the new object.");
            return;
        }

        handleAddBackground(name, type, isMaster, width, height, dic_index, code);

        setName('');
        setCode('');
        setType('#BackGround')
        setDic_index(10000);
        setIsMaster(false);
        closeModal();
    };

    const renderTabContent = () => {
        if (activeTab === 'User') {
            return (
                <div className='Cws-bg-set-user-con'>
                <div className="Cws-bg-set-upload-container">
                    <label className="Cws-bg-set-upload">
                        <input type="file" onChange={handleFileUpload} />
                        <span>Click to upload</span>
                    </label>
                </div>
            
                <div className="Cws-bg-set-preview-container">
                    <img className="Cws-bg-set-preview-img" src={previewImageUrl} alt="Preview" />
                </div>
            
                <div className="Cws-bg-set-inputs">
                    <input
                        type="number"
                        className="Cws-bg-set-input"
                        placeholder="Number (>= 10000)"
                        value={imageNum}
                        onChange={(e) => setImageNum(e.target.value || 10000)}
                    />
                    <button className="Cws-bg-set-button" onClick={handleAddImage}>Add Image</button>
                </div>
            </div>
            );
        } else if (activeTab === 'Tile') {
            return (
                <div className='Cws-bg-set-tile-page'>
                    <div className='Cws-bg-set-tile-page-top'>
                        <div className="Cws-bg-set-page">
                            {TileList.map((icon, index) => (
                                <div
                                    key={index}
                                    className={`Cws-bg-set-box ${selectedIcon === icon.src ? 'active' : ''}`}
                                >
                                    <div className='Cws-bg-set-img-box'>
                                        <img src={icon.src} alt={icon.name} />
                                    </div>
                                    <div className="Cws-bg-set-box-name">
                                        <span>{icon.dic_index}: </span>
                                        <span>{icon.name}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='Cws-bg-set-tile-page-bottom'>
                        
                        <div className='Cws-bg-set-tile-page-bottom-t'>
                            
                            <div className='Cws-bg-set-tile-page-bottom-t-box'>
                            <span>Tile Num: </span>    
                                <input
                                    type="number"
                                    placeholder="row"
                                    value={tileRow}

                                    onChange={(e) => setTileRow(e.target.value || 3)}
                                />

                                <span>X</span>
                                
                                <input
                                    type="number"
                                    placeholder="col"
                                    value={tileCol}

                                    onChange={(e) => setTileCol(e.target.value || 3)}
                                />
                                <button className="Cws-bg-set-tile-page-bottom-t-button" onClick={handleTileMapMaker}>Make Tile</button>
                            </div>
                            
                        </div>
                        <div className='Cws-bg-set-tile-page-bottom-b'>
                            <div className="Cws-bg-set-fixed-editor">
                                <CwsToggleEditor
                                    explainSentance="Tile Code"
                                    initialValue={code}
                                    onChange={handleEditorChange}
                                    isOpen={true}
                                    handleToggle={() => { }} // If you need a toggle function, add it here
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="Cws-bg-set-page">
                    {imgList.map((icon, index) => (
                        <div
                            key={index}
                            className={`Cws-bg-set-box ${selectedIcon === icon.src ? 'active' : ''}`}
                            onClick={() => {
                                setSelectedIcon(icon.src);
                                setDic_index(icon.dic_index);
                            }}
                        >
                            <div className='Cws-bg-set-img-box'>
                                <img src={icon.src} alt={icon.name} />
                            </div>
                            <div className="Cws-bg-set-box-name">

                                <span>{icon.name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }


    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Add New Object"
            className="Cws-bg-set-modal"
            overlayClassName="Cws-bg-set-overlay"
        >
            <div className="Cws-bg-set-sidebar">
                <div className='Cws-bg-set-category-top'>
                    <div className={`Cws-bg-set-category-box ${activeTab === 'All' ? 'active' : ''}`} onClick={() => {
                        setType("#BackGround");
                        setActiveTab('All');
                    }}>
                        <IoGameController size="20" />
                        <span style={{ color: "#000", fontFamily: "Inter", fontSize: "14px", marginLeft: "10px" }}>{text.bgSet.bg}</span>
                    </div>
                    <div className={`Cws-bg-set-category-box ${activeTab === 'Tile' ? 'active' : ''}`} onClick={() => {
                        setActiveTab('Tile');
                        setType("#Tile");
                    }}>
                        <AiOutlineBuild size="20" />
                        <span style={{ color: "#000", fontFamily: "Inter", fontSize: "14px", marginLeft: "10px" }}>{text.bgSet.tile}</span>
                    </div>

                    <div className={`Cws-bg-set-category-box ${activeTab === 'User' ? 'active' : ''}`} onClick={() => {
                        setType("#BackGround");
                        setActiveTab('User');

                    }}>
                        <FaUpload size="20" />
                        <span style={{ color: "#000", fontFamily: "Inter", fontSize: "14px", marginLeft: "10px" }}>{text.bgSet.upload}</span>
                    </div>

                </div>
                <div>
                    <div className="Cws-bg-set-inputs">
                        <div className='Cws-bg-set-input-box'>
                            <span>{text.bgSet.width}: </span>
                            <input
                                type="number"
                                placeholder="Width (default: 100)"
                                value={width}
                                onChange={(e) => setWidth(Number(e.target.value) || 100)} // Ensuring the input is treated as a number
                            />
                        </div>
                        <div className='Cws-bg-set-input-box'>
                            <span>{text.bgSet.height}: </span>
                            <input
                                type="number"
                                placeholder="Height (default: 100)"
                                value={height}
                                onChange={(e) => setHeight(Number(e.target.value) || 100)}
                            />
                        </div>
                    </div>
                    <div className="Cws-bg-set-checkbox">
                        <TbTower size={15} />
                        <span style={{ fontFamily: "Inter", fontSize: "12px", marginRight: "2px", marginLeft: "5px" }}>{text.bgSet.masterbg}</span>


                        <input
                            type="checkbox"
                            checked={isMaster}
                            onChange={() => setIsMaster(!isMaster)}
                            style={{ display: "inline" }}
                        />
                    </div>
                    <div className="Cws-bg-set-inputs">

                        <input
                            type="text"
                            placeholder={text.bgSet.bgname}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <button className="Cws-bg-set-custom-button" onClick={addNewBackGround}>{text.bgSet.add}</button>
                    </div>
                </div>
            </div>
            <div className='Cws-bg-set-selection-con'>
                {renderTabContent()}
            </div>
            <div className="Cws-bg-set-close-icon" onClick={closeModal}>
                <RiCloseCircleFill size="25" />
            </div>
        </Modal>
    );
}

export default BackGroundSetModal;
