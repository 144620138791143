import navTexts from './TextSet/navTexts';
import typoNavTexts from './TextSet/typoNavTexts';
import editorTexts from './TextSet/editorTexts';
import leftSecTexts from './TextSet/leftSecTexts';
import mainTexts from './TextSet/mainTexts';
import bgSecTexts from './TextSet/bgSecTexts';
import obSecTexts from './TextSet/obSecTexts';
import rightSecTexts from './TextSet/rightSecTexts';
import codeEditorTexts from './TextSet/codeEditorTexts';
import commandSideTexts from './TextSet/commandSideTexts';
import commandInTexts from './TextSet/commandInTexts';
import commandsTexts from './TextSet/commandsTexts';
import bgSetTexts from './TextSet/bgSetTexts';
import objectSetTexts from './TextSet/objectSetTexts';

const texts = {
    en: {
        nav: navTexts.en,
        typo_nav: typoNavTexts.en,
        editor: editorTexts.en,
        leftSec: leftSecTexts.en,
        main: mainTexts.en,
        bgSec: bgSecTexts.en,
        bgSet: bgSetTexts.en,
        obSec: obSecTexts.en,
        obSet: objectSetTexts.en,
        rightSec: rightSecTexts.en,
        codeEditor: codeEditorTexts.en,
        command_side: commandSideTexts.en,
        command_in: commandInTexts.en,
        commands: commandsTexts.en,
    },
    ko: {
        nav: navTexts.ko,
        typo_nav: typoNavTexts.ko,
        editor: editorTexts.ko,
        leftSec: leftSecTexts.ko,
        main: mainTexts.ko,
        bgSec: bgSecTexts.ko,
        bgSet: bgSetTexts.ko,
        obSec: obSecTexts.ko,
        obSet: objectSetTexts.ko,
        rightSec: rightSecTexts.ko,
        codeEditor: codeEditorTexts.ko,
        command_side: commandSideTexts.ko,
        command_in: commandInTexts.ko,
        commands: commandsTexts.ko,
    }
};

export default texts;
