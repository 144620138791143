import React, { useEffect } from 'react';
import TypoNavBar from './TypoNavBar';
import TypoMainBanner from './TypoMainBanner';
import TypoExplorePage from './TypoExplorePage';
import TypoLoginPage from './TypoLoginPage';
import TypoMainFooter from './TypoMainFooter';
import TypoBestUserBanner from './TypoBestUserBanner';
import "./TypoMainPage.css";

function TypoMainPage() {

    useEffect(() => {
        // Clear the saved project when returning to the main page
        localStorage.removeItem('currentProject');
    }, []);

    return (
        <div className='TypoMainPage-con'>
            <div className='TypoMainPage-head'>
                <TypoNavBar />
            </div>
            <div className='TypoMainPage-body'>
                <div className='TypoMainPage-body-box'>
                    <TypoMainBanner />
                </div>
                <div className='TypoMainPage-body-box'>
                    <TypoBestUserBanner />
                </div>
                <div className='TypoMainPage-body-box'>
                    <TypoMainFooter />
                </div>
            </div>
        </div>
    );
}

export default TypoMainPage;
