import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import { IoPersonCircle } from 'react-icons/io5';
import firebaseApp from '../../api/firebase';
import { Link, useNavigate } from 'react-router-dom';
import './CwsNavBar.css';
import logo_img from '../../img_set/logo/typo_logo.png';
import { FiUploadCloud } from "react-icons/fi";
import { LuDownloadCloud } from "react-icons/lu";
import axios from 'axios';
import ProjectModal from './Modal_set/CwsProjectModal';
import CwsSaveProjectModal from './Modal_set/CwsSaveProjectModal';
import CwsLoadModal from './Modal_set/CwsLoadModal';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { FaGamepad } from "react-icons/fa6";
import { TbHammer } from "react-icons/tb";
import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';

import useStore from '../GlobalStore';

const storage = getStorage(firebaseApp);

function CwsNavBar({ project, setProject, handleCompileRun, sumImg }) {
    const { isLoggedIn, userName, logout, userId } = useStore();
    
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
    const [projectIds, setProjectIds] = useState([]);
    const [chaptersWithProjects, setChaptersWithProjects] = useState([]);
    const ENDPOINT = process.env.REACT_APP_CloudTypeServer || "http://localhost:4000";

    const location = useLocation();
    const navigate = useNavigate();
    const { language, setLanguage } = useLanguage("ko");

    const text = texts[language];
    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
                Swal.fire('Logged out!', 'You have been logged out successfully.', 'success');
                navigate('/'); // Redirect to home after logout
            }
        });
    };

    const handleLoginClick = () => {
        if (isLoggedIn) {
            handleLogout();
        } else {
            navigate('/login', { state: { from: location } });
        }
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    async function urlToFile(url, filename, mimeType) {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();
        return new File([buffer], filename, { type: mimeType });
    }

    const uploadImagesToFirebase = async () => {
        const userBgDicUrls = [];
        const userImgDicUrls = [];
        const userFolder = `users/${userId}`; // Define the user folder based on userId

        // Upload user background images
        for (const entry of project.userBgDic) {
            const { url, fileName } = entry;
            const file = await urlToFile(url, fileName, 'image/png');
            const storageRef = ref(storage, `${userFolder}/${project.name}/${fileName}`); // Use project name and file name
            const snapshot = await uploadBytes(storageRef, file);
            const downloadUrl = await getDownloadURL(snapshot.ref);
            userBgDicUrls.push({ url: downloadUrl, fileName: fileName, imageNum: entry.imageNum });
        }

        // Upload user images
        for (const entry of project.userImgDic) {
            const { url, fileName, width, height } = entry;
            const file = await urlToFile(url, fileName, 'image/png');
            const storageRef = ref(storage, `${userFolder}/${project.name}/${fileName}`); // Use project name and file name
            const snapshot = await uploadBytes(storageRef, file);
            const downloadUrl = await getDownloadURL(snapshot.ref);
            userImgDicUrls.push({ url: downloadUrl, fileName: fileName, width: width, height: height });
        }

        return { userBgDicUrls, userImgDicUrls };
    };

    const handleSave = async (name, description) => {
        if (!isLoggedIn) {
            Swal.fire({
                title: "Save error",
                text: "You must be logged in to save your project.",
                icon: "error"
            });
            return;
        }

        try {
            if (name.startsWith('Class') && !description.startsWith('Wkwkdaus1!')) {
                Swal.fire({
                    title: "Save error",
                    text: "Projects starting with 'Class' must have a description starting with 'Wkwkdaus1!'",
                    icon: "error"
                });
                return;
            }

            const { userBgDicUrls, userImgDicUrls } = await uploadImagesToFirebase();
            const updatedProject = {
                ...project,
                name,
                description,
                userBgDic_url: userBgDicUrls,
                userImgDic_url: userImgDicUrls
            };

            console.log("save", updatedProject);
            setProject(updatedProject);

            await handleCompileRun();
            await axios.post(`${ENDPOINT}/api/save`, { updatedProject, sumImg, userId });
            Swal.fire({
                title: "Save Success",
                icon: "success"
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Save error",
                text: "Failed to save project.",
                icon: "error"
            });
        }
    };

    const loadImagesFromFirebase = async (projectData) => {
        const userBgDics = [];
        const userImgDics = [];
        const updateObjects = projectData.objects;

        for (const entry of projectData.userImgDic_url) {
            const { url, fileName, width, height } = entry;
            const response = await fetch(url);
            const blob = await response.blob();
            const localUrl = URL.createObjectURL(blob);
            const userImgDicEntry = {
                url: localUrl,
                fileName: fileName,
                width: width,
                height: height,
                code: `
user_${fileName}_img_dic = {
    "default": {
        "frame": 1,
        "f-width-full": ${width},
        "f-width": ${width},
        "f-height": ${height},
        "draw_width": ${width},
        "draw_height": ${height},
        "hit_x": 0,
        "hit_y": 0,
        "hit_w": ${width},
        "hit_h": ${height},
        "img-url": '${localUrl}'
    },
}
    `
            };
            userImgDics.push(userImgDicEntry);
            updateObjects[fileName].icon = localUrl;
        }

        for (const entry of projectData.userBgDic_url) {
            const { url, fileName, imageNum } = entry;
            const response = await fetch(url);
            const blob = await response.blob();
            const localUrl = URL.createObjectURL(blob);
            const userBgDicEntry = {
                url: localUrl,
                fileName: fileName,
                imageNum: imageNum,
                code: `
        "user_${imageNum}": {
            "px_w": 2304, 
            "px_h": 1536, 
            "img-url": "${url}",
            "is_wall": False
        },
            `
            };
            userBgDics.push(userBgDicEntry);
        }

        return { userBgDics, userImgDics, updateObjects };
    };

    const handleSelectProject = async (projectId) => {
        try {
            const response = await axios.get(`${ENDPOINT}/api/load/${projectId}`, { params: { userId } });
            const projectData = response.data;

            const { userBgDics, userImgDics, updateObjects } = await loadImagesFromFirebase(projectData);

            const updatedProject = {
                name: projectData.name,
                description: projectData.description,
                objects: updateObjects,
                masterCode: projectData.masterCode,
                compileCode: projectData.compileCode,
                scenes: projectData.scenes,
                userBgDic: userBgDics,
                userImgDic: userImgDics,
                userBgDic_url: [],
                userImgDic_url: []
            };

            setIsProjectModalOpen(false);
            console.log(updatedProject);

            setProject(prevProject => ({
                ...prevProject,
                ...updatedProject
            }));

            Swal.fire({
                title: "Load Success",
                text: `${projectData.name} loaded! Click Run!`,
                icon: "success"
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Load error",
                text: "Failed to retrieve project.",
                icon: "error"
            });
        }
    };

    const handleLoadProject = async (projectId) => {
        try {
            const response = await axios.get(`${ENDPOINT}/api/load/${projectId}`, { params: { userId } });
            const projectData = response.data;

            const { userBgDics, userImgDics, updateObjects } = await loadImagesFromFirebase(projectData);

            setIsLoadModalOpen(false);
            setProject(prevProject => ({
                ...prevProject,
                ...projectData,
                userBgDic: userBgDics,
                userImgDic: userImgDics,
                objects: updateObjects
            }));

            Swal.fire({
                title: "Load Success",
                text: `${projectData.name} loaded! Click Run!`,
                icon: "success"
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Load error",
                text: "Failed to retrieve project.",
                icon: "error"
            });
        }
    };

    const handleShare = async () => {
        try {
            const response = await axios.get(`${ENDPOINT}/api/projects`, {
                params: { userId } // Pass userId as a query parameter
            });
            const projects = response.data.filter(project => !project.name.startsWith('Class'));
            setProjectIds(projects);
            setIsProjectModalOpen(true);
        } catch (error) {
            Swal.fire({
                title: "Load error",
                text: "Failed to retrieve project IDs.",
                icon: "error"
            });
        }
    };

    const handleLoad = async () => {
        try {
            const response = await axios.get(`${ENDPOINT}/api/projects`, {
                params: { userId } // Pass userId as a query parameter
            });
            const projects = response.data.filter(project => project.name.startsWith('Class'));
            setProjectIds(projects);
            categorizeProjects(projects);
            setIsLoadModalOpen(true);
        } catch (error) {
            Swal.fire({
                title: "Load error",
                text: "Failed to retrieve project IDs.",
                icon: "error"
            });
        }
    };

    const categorizeProjects = (projectList) => {
        const chapterProjects = {
            'Class 1': {},
            'Class 2': {},
            'Class 3': {},
            'free': {}
        };

        projectList.forEach(project => {
            const { name, description, _id } = project;
            if (name.startsWith('Class')) {
                const [classKey, chapterKey, stageKey] = name.split('-');
                const className = `Class ${classKey.replace('Class', '')}`;
                const chapterName = `Chapter ${chapterKey}`;

                const projectEntry = { name, description, _id };

                if (!chapterProjects[className][chapterName]) {
                    chapterProjects[className][chapterName] = [];
                }

                chapterProjects[className][chapterName].push(projectEntry);
            } else {
                const projectEntry = { name, description, _id };
                if (!chapterProjects['free']['free']) {
                    chapterProjects['free']['free'] = [];
                }
                chapterProjects['free']['free'].push(projectEntry);
            }
        });

        const chapters = Object.keys(chapterProjects).map(classKey => {
            const sortedChapters = Object.keys(chapterProjects[classKey])
                .sort((a, b) => parseInt(a.split(' ')[1]) - parseInt(b.split(' ')[1]))
                .map(chapterKey => {
                    const sortedProjects = chapterProjects[classKey][chapterKey]
                        .sort((a, b) => {
                            const stageA = a.name.split('-')[2];
                            const stageB = b.name.split('-')[2];
                            return parseInt(stageA) - parseInt(stageB);
                        });
                    return {
                        name: chapterKey,
                        projects: sortedProjects
                    };
                });
            return {
                name: classKey,
                chapters: sortedChapters
            };
        });

        setChaptersWithProjects(chapters);
    };

    useEffect(() => {
        const loadDefaultProject = async () => {
            if (location.pathname.includes('/compiler/1/1')) {
                try {
                    const response = await axios.get(`${ENDPOINT}/api/test`);
                    const projectData = response.data;
                    setProject(projectData);
                    Swal.fire({
                        title: `Click Run!`,
                        text: "Loaded 'Sheep want cherry' ",
                        icon: "success"
                    });
                } catch (error) {
                    Swal.fire({
                        title: "Failed to load 'Sheep want cherry'",
                        icon: "error"
                    });
                }
            }
        };
        loadDefaultProject();
    }, [location.pathname, ENDPOINT, setProject]);

    const classList = ['Class 1', 'Class 2', 'Class 3', 'making'];

    return (
        <div className='Cws-nav-con'>
            <div className='Cws-nav-box-l'>
                <Link to="/">
                    <img
                        src={logo_img}
                        width="109"
                        height="50"
                        alt="Logo"
                    />
                </Link>
                <select value={language} onChange={handleLanguageChange} className="language-selector">
                    <option value="en">English</option>
                    <option value="ko">한국어</option>
                </select>
            </div>
            <div className='Cws-nav-box-m'>
            </div>
            <div className='Cws-nav-box-r'>
                {isLoggedIn ? (
                    <>
                        <div className='Cws-nav-item-box' onClick={() => setIsSaveModalOpen(true)}>
                            <FiUploadCloud size={20} />
                            <span style={{ fontSize: "14px", marginLeft: "10px" }}>{text.nav.save}</span>
                        </div>
                        <div className='Cws-nav-item-box' onClick={() => handleShare()}>
                            <LuDownloadCloud size={20} />
                            <span style={{ fontSize: "14px", marginLeft: "10px" }}>{text.nav.load}</span>
                        </div>
                        <div className='Cws-nav-item-box' onClick={() => handleLoad()}>
                            <FaGamepad size={20} />
                            <span style={{ fontSize: "14px", marginLeft: "10px" }}>{text.nav.stage}</span>
                        </div>
                    </>
                ) : null}
                <div className='Cws-nav-item-box' onClick={handleLoginClick}>
                    <IoPersonCircle size={20} />
                    <span>{isLoggedIn ? `${userName} (Logout)` : text.typo_nav.login}</span>
                </div>
            </div>
            <ProjectModal
                isOpen={isProjectModalOpen}
                onRequestClose={() => setIsProjectModalOpen(false)}
                projectIds={projectIds}
                onSelect={handleSelectProject}
            />
            <CwsSaveProjectModal
                isOpen={isSaveModalOpen}
                onRequestClose={() => setIsSaveModalOpen(false)}
                onSave={handleSave}
                initialName={project.name}
                initialDescription={project.description}
            />
            <CwsLoadModal
                isOpen={isLoadModalOpen}
                onRequestClose={() => setIsLoadModalOpen(false)}
                classList={classList}
                chapters={chaptersWithProjects}
                onSelect={handleLoadProject}
                filterType="load"
            />
        </div>
    );
}

export default CwsNavBar;
