const leftSecTexts = {
    en: {
        command: "Command",
        learning: "Learning",
        scene: "Scene",
        masterCode: "Global Code",
        addScene: "Add Scene",
    },
    ko: {
        command: "명령어 사전",
        learning: "학습하기",
        scene: "장면",
        masterCode: "전역 코드",
        addScene: "장면 추가",
    },
};

export default leftSecTexts;
