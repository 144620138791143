import React, { useState } from 'react';
import CwsCommand from './CwsCommand';
import './CwsCommandEx.css';
import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';
import { LuMove } from 'react-icons/lu';
import { IoPersonCircle } from 'react-icons/io5';
import { FaArrowsDownToLine, FaCircleHalfStroke, FaCodeBranch, FaClone } from 'react-icons/fa6';

const CwsCommandEx = ({ selectedObjectName }) => {
    const { language } = useLanguage();
    const commands = texts[language].commands;
    const [selectedCategory, setSelectedCategory] = useState(null);
    const side_text = texts[language].command_side;
    const categories = [
        { name: 'move', icon: <LuMove className="icon move" />, label: 'motion' },
        { name: 'feature', icon: <IoPersonCircle className="icon feature" size={30}/>, label: 'feature' },
        { name: 'return', icon: <FaArrowsDownToLine className="icon return" />, label: 'return' },
        { name: 'check', icon: <FaCircleHalfStroke className="icon recon" />, label: 'check' },
        { name: 'flow', icon: <FaCodeBranch className="icon flow" />, label: 'flow' },
        { name: 'clone', icon: <FaClone className="icon clone" />, label: 'clone' },
    ];

    const filteredCommands = selectedCategory
        ? commands.filter(cmd => cmd.category === selectedCategory)
        : commands;

    return (
        <div className="Cws-command-ex-con">
            <div className="Cws-command-ex-side">
                {categories.map(category => (
                    <div
                        key={category.name}
                        className={`Cws-command-ex-side-icon-box ${selectedCategory === category.name ? 'selected' : ''}`}
                        onClick={() => setSelectedCategory(category.name)}
                    >
                        {category.icon}
                        <span>{side_text[category.label]}</span>
                    </div>
                ))}
            </div>
            <div className="Cws-command-ex-main-con">
                <div className="Cws-command-ex-main">
                {filteredCommands.map((cmd, index) => (
                    <CwsCommand
                        key={index}
                        name={cmd.name}
                        parameters={Array.isArray(cmd.parameters) ? cmd.parameters : []}
                        selectedObjectName={selectedObjectName}
                    />
                ))}
                </div>
            </div>
        </div>
    );
};

export default CwsCommandEx;
