import React, { useState } from 'react';
import Modal from 'react-modal';
import "./ImageSelectionModal.css";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoGameController } from "react-icons/io5";
import { TbTower } from "react-icons/tb";
import { FaUpload } from "react-icons/fa6";
import Swal from 'sweetalert2';
import { useLanguage } from '../../../LanguageContext';
import texts from '../../../texts';

const ImageSelectionModal = ({ isOpen, onRequestClose, imgList, setImgList, selectedIcon, setSelectedIcon, newObject, setNewObject, handleAddObject }) => {
    const [activeTab, setActiveTab] = useState('All'); // State to handle active tab
    const [width, setWidth] = useState(100); // State to handle width input
    const [height, setHeight] = useState(100); // State to handle height input
    const [previewImageUrl, setPreviewImageUrl] = useState(null); // State to handle image preview

    const { language } = useLanguage();
    const text = texts[language];
    

    const handleFileUpload = (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                const url = URL.createObjectURL(file);
                const newIcon = { name: `user_${file.name}`, src: url };
                setImgList(prevImgList => [...prevImgList, newIcon]);
                setSelectedIcon(url);
                setPreviewImageUrl(url); // Set the preview image URL
            }
    
            Swal.fire({
                title: "Load Success",
                text: `Check Object tab!`,
                icon: "success"
            });
        } catch (error) {
            Swal.fire({
                title: "Load error",
                text: "Failed to upload image.",
                icon: "error"
            });
        }
    };

    const closeModal = () => {
        onRequestClose();
        setPreviewImageUrl(null); // Clear the preview image when modal is closed
    };

    const addNewObject = () => {
        if (!newObject.name.trim()) {
            alert("Please enter a name for the new object.");
            return;
        }
        handleAddObject(width, height); // Now passing width and height
        closeModal();
    };

    const renderTabContent = () => {
        if (activeTab === 'User') {
            return (
                <div className='Cws-ob-selection-user-con'>
                    <label className="Cws-ob-selection-upload">
                        Upload Image
                        <input type="file" onChange={handleFileUpload} />
                        <span>Click to upload</span>
                    </label>
                    {previewImageUrl && (
                        <div className="Cws-ob-selection-preview-container">
                            <img className="Cws-ob-selection-preview-img" src={previewImageUrl} alt="Preview" />
                        </div>
                    )}
                    <div className="Cws-ob-selection-inputs">
                        <div className='Cws-ob-selection-input-box'>
                            <span>{text.obSet.width}: </span>
                            <input
                                type="number"
                                placeholder="Width (default: 100)"
                                value={width}
                                onChange={(e) => setWidth(Number(e.target.value) || 100)}
                            />
                        </div>
                        <div className='Cws-ob-selection-input-box'>
                            <span>{text.obSet.height}: </span>
                            <input
                                type="number"
                                placeholder="Height (default: 100)"
                                value={height}
                                onChange={(e) => setHeight(Number(e.target.value) || 100)}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="Cws-ob-selection-page">
                {imgList.map((icon, index) => (
                    <div
                        key={index}
                        className={`Cws-ob-selection-box ${selectedIcon === icon.src ? 'active' : ''}`}
                        onClick={() => {
                            setSelectedIcon(icon.src);
                            setNewObject({ ...newObject, type: icon.name });
                        }}
                    >
                        <div className='Cws-ob-selection-img-box'>
                            <img src={icon.src} alt={icon.name} />
                        </div>
                        <div className="Cws-ob-selection-box-name">
                            <span>{icon.name}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Add New Object"
            className="Cws-ob-selection-modal"
            overlayClassName="Cws-ob-selection-overlay"
        >
            <div className="Cws-ob-selection-sidebar">
                <div className='Cws-ob-selection-category-top'>
                    <div className={`Cws-ob-selection-category-box ${activeTab === 'All' ? 'active' : ''}`} onClick={() => setActiveTab('All')}>
                        <IoGameController size="20" />
                        <span style={{ color: "#000", fontFamily: "Inter", fontSize: "14px", marginLeft: "10px" }}>{text.obSet.objects}</span>
                    </div>
                    <div className={`Cws-ob-selection-category-box ${activeTab === 'User' ? 'active' : ''}`} onClick={() => setActiveTab('User')}>
                        <FaUpload size="20"/>
                        <span style={{ color: "#000", fontFamily: "Inter", fontSize: "14px", marginLeft: "10px" }}>{text.obSet.upload}</span>
                    </div>
                </div>
                <div>
                    <div className="Cws-ob-selection-checkbox">
                        <TbTower size={15} />
                        <span>{text.obSet.masterobject}</span>
                        <input
                            type="checkbox"
                            checked={newObject.isMaster}
                            onChange={(e) => setNewObject({ ...newObject, isMaster: e.target.checked })}
                        />
                    </div>
                    <div className="Cws-ob-selection-inputs">
                        <input
                            type="text"
                            placeholder={text.obSet.name}
                            value={newObject.name}
                            onChange={(e) => setNewObject({ ...newObject, name: e.target.value })}
                        />
                        <button className="Cws-ob-selection-custom-button" onClick={addNewObject}>{text.obSet.add}</button>
                    </div>
                </div>
            </div>
            <div className='Cws-ob-selection-selection-con'>
                {renderTabContent()}
            </div>
            <div className="Cws-ob-selection-close-icon" onClick={closeModal}>
                <RiCloseCircleFill size="25" />
            </div>
        </Modal>
    );
}

export default ImageSelectionModal;
