const rightSecTexts = {
    en: {
        terminal: "Terminal",
        backGround: "BackGround",
        object: "Object",
    },
    ko: {
        terminal: "터미널",
        backGround: "배경",
        object: "객체",
        output: "출력",
    },
};

export default rightSecTexts;
