import React, { useState } from 'react';
import Modal from 'react-modal';
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import './CwsRenameModal.css';


const RenameModal = ({ isOpen, onRequestClose, currentName, newName, setNewName, onRename }) => {
    const handleInputChange = (e) => {
        setNewName(e.target.value);
    };

    const handleRename = () => {
        onRename();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Rename Modal"
            className="rename-modal"
            overlayClassName="rename-overlay"
        >
            <span style={{ fontFamily: "Inter", fontSize: "15px", marginRight: "2px", marginLeft: "5px" }}>Rename Object</span>
            <div className="rename-modal-input-group">
                <MdOutlineDriveFileRenameOutline size={25} />
                <input
                    type="text"
                    placeholder={currentName}
                    value={newName}
                    onChange={handleInputChange}
                />
            </div>
            <div className="rename-modal-button-group">
                <button onClick={handleRename} className="rename-modal-add-button">Rename</button>
                <button onClick={onRequestClose} className="rename-modal-cancel-button">Cancel</button>
            </div>
        </Modal>
    );
};

export default RenameModal;
