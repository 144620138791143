
import Iframe from 'react-iframe';


import React, { useState, useEffect } from 'react';

function CwsNotionView({notionURL}) {



  return (
    <Iframe
      url={notionURL}
      width="100%"
      height="100%"
      id="Cws-iframe"
      display="block"
      position="relative"
      allowFullScreen
      className='Cws-res-iframe'
    />
  );
}

export default CwsNotionView;