const editorTexts = {
    en: {
        editor: "Code Editor",
        runCode: "Run Code",
        resetCode: "Reset Code",
        local: "local",
        global: "global",
    },
    ko: {
        editor: "코드 편집기",
        runCode: "코드 실행",
        resetCode: "코드 초기화",
        local: "로컬",
        global: "글로벌",
    },
};

export default editorTexts;
