const obSecTexts = {
    en: {
        objectSettings: "Object Settings",
        objectName: "Object",
        objectType: "Object Type",
        direction: "Direction",
    },
    ko: {
        objectSettings: "객체 설정",
        objectName: "객체 이름",
        objectType: "객체 유형",
        direction: "방향",
    },
};

export default obSecTexts;
