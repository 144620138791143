import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./TypoBestUserBanner.css";

function TypoBestUserBanner() {
    const [topTypoWorks, setTopTypoWorks] = useState([]);
    const ENDPOINT = process.env.REACT_APP_CloudTypeServer || "http://localhost:4000";
    const navigate = useNavigate();

    useEffect(() => {
        fetchTopTypoWorks();
    }, []);

    const fetchTopTypoWorks = async () => {
        try {
            const response = await Axios.get(`${ENDPOINT}/api/typowork/top-typo-works`);
            setTopTypoWorks(response.data);
        } catch (error) {
            console.error('Error fetching top typo works:', error);
        }
    };

    const handleWorkClick = (workId) => {
        navigate(`/typowork/${workId}`);
    };

    return (
        <div className='Typo-best-user-banner-con'>
            <div className='Typo-best-user-banner-head'>
                <h2>Best Typer</h2>
                <span>가장 뛰어났던 Typer!</span>
                
            </div>
            <div className='Typo-best-user-banner-body'>
                <div className='Typo-best-user-banner-body-box-list'>
                    {topTypoWorks.map((work, index) => (
                        <div 
                            key={index} 
                            className={`Typo-best-user-banner-body-box ${index === 0 ? 'active' : ''}`}
                            onClick={() => handleWorkClick(work._id)}  // 작품 클릭 시 이동
                        >
                            <div className='Typo-best-user-banner-body-box-top'>
                                <img src={work.sumimglink} alt={work.title} />
                            </div>
                            <div className='Typo-best-user-banner-body-box-bottom'>
                                <h3>{work.title}</h3>
                                <p>by {work.user_id.id}</p>
                                <p>Score: {work.view + work.like * 2}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='Typo-best-user-banner-footer'>
                {/* 여기에 footer 내용을 추가하세요 */}
            </div>
        </div>
    );
}

export default TypoBestUserBanner;
